<template>
  <div ref="ApplicantsDisplayer">
    <!-- Row with les cards on responsive layout -->
    <!-- need scroll CEST NON -->
    <div class="row">
      <template v-if="applicantDisplayerStore.items.length">
        <div
          v-for="(displayItem, iDisplayItem) of applicantDisplayerStore.items"
          :key="iDisplayItem"
          :class="cardsClasses"
        >
          <ApplicantCard
            :already-fed="displayItem"
            :prop-loading="applicantDisplayerStore.loadingIds.includes(displayItem.id)"
            :lock-cvtheque="lockCvtheque"
            @chipClick="handleActions($event)"
          >
            <template #checkbox-select>
              <QCheckbox
                v-if="isSubscribed() && isAdministratorInOrg() && !isPremium()"
                v-model="selectedUnlock"
                color="positive"
                :val="displayItem.id"
              >
                <ActionTooltip path="shop.cvtheque.unlock_applicant" />
              </QCheckbox>
            </template>
          </ApplicantCard>
        </div>
        <QPageSticky v-if="isSubscribed() && selectedUnlock.length" position="bottom-right" :offset="[18, 18]">
          <HCBigButton
            :label="$t({ id: 'shop.cvtheque.unlock_applicants' }, { nb: selectedUnlock.length })"
            icon="uil:unlock"
            @click="handleActions({
              action: 'askUnlock',
              selectedUnlock: selectedUnlock
            })"
          />
        </QPageSticky>
      </template>

      <!-- No data -->
      <div v-else class="full-width row flex-center text-negative q-gutter-sm">
        <QIcon size="2em" name="uil:confused" />
        <AppContent path="error.no_data" />
      </div>
    </div>

    <ApplicantDialog
      v-if="applicantDisplayerStore.showDialog && applicantDisplayerStore.applicantId && !externalDialog"
      :applicant-id="applicantDisplayerStore.applicantId"
      :lock-cvtheque="lockCvtheque"
      :bottom-navigation="bottomNavigation"
      @hide="$store.commit('user/setAppDispProperty', { showDialog: false })"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import { dlKeyFromS3 } from 'hc-core/composables/aws.js'
// import HCMenu from 'hc-core/components/common/hc-menu'
import { ensureParamToArray, openMapsOnLocation } from 'hc-core/composables/misc.js'
import ApplicantListMixins from 'hc-core/mixins/applicant-list.js'
import ApplicantCard from 'hc-core/components/cards/applicant-card'
import ApplicantDialog from 'hc-core/components/dialogs/applicant-dialog'

export default {
  components: { ApplicantDialog, ApplicantCard },
  mixins: [ApplicantListMixins],
  props: {
    displayCards: {
      type: Boolean,
      default: true
    },
    lockCvtheque: {
      type: Boolean,
      default: false
    },
    cardsClasses: {
      type: String,
      default: 'col-12 col-md-4 col-sm-6 q-pa-xs'
    },
    externalDialog: {
      type: Boolean,
      default: false
    },
    bottomNavigation: {
      type: Boolean,
      default: false
    },
  },
  emits: ['refresh'],
  data () {
    return {
      // For multiple unlock at once
      selectedUnlock: []
    }
  },
  computed: {
    applicantDisplayerStore () { return this.$store.state.user.applicantDisplayerStore },
    isVivier () { return this.$route.name === 'applicants' },
  },
  created () {
    this.$store.commit('user/setAppDispProperty', { loading: false })
    window.addEventListener('keydown', this.appDialogKbHandler)
  },
  unmounted () {
    window.removeEventListener('keydown', this.appDialogKbHandler)
  },
  methods: {
    appDialogKbHandler (event) {
      // Left
      if (event.keyCode === 37) this.$store.commit('user/setAppDispProperty', 'goPrevItem')
      // Right
      if (event.keyCode === 39) this.$store.commit('user/setAppDispProperty', 'goNextItem')
    },

    // ##### Below are computedItems snippets #####
    async handleActions (event) {
      switch (event.action) {
        case 'resume':
        case 'files':
        case 'messages':
        case 'applications':
        case 'openRestricted':
          this.$store.commit('user/setAppDispProperty', {
            applicantId: event.value.id,
            tab: event.action !== 'openRestricted' ? event.action : 'applications',
            showDialog: true,
          })
          break
        case 'downloadResume':
          await dlKeyFromS3({ type: 'pdf', key: this.$_.get(event, 'value.metadata._files.resume', null), dlLabel: `CV_${this.$_.get(event, 'value.firstname', '')}_${this.$_.get(event, 'value.lastname', '')}` })
          break
        case 'openMapsOnLocation':
          openMapsOnLocation(event.location)
          break
        case 'askUnlock':
          try {
            const targetIds = event.selectedUnlock ?? event.value.id
            this.$store.commit('user/setAppDispProperty', { loadingIds: ensureParamToArray(targetIds) })
            await this.unlockCvthequeApplicant(targetIds)

            // Set updated in applicants in store
            const updatedApplicants = await this.$store.dispatch('user/list', {
              filters: { id: targetIds }
            })
            const newItems = this.applicantDisplayerStore.items
            for (const updatedApplicant of updatedApplicants.results) {
              const index = newItems.findIndex(({ id }) => id === updatedApplicant.id)
              if (index) {
                this.$_.set(updatedApplicant, 'profileAsset', this.$_.get(newItems, `[${index}].profileAsset`, undefined))
                this.$_.set(newItems, `[${index}]`, updatedApplicant)
              }
            }
            this.$store.commit('user/setAppDispProperty', { items: newItems })

            // Open on success
            setTimeout(() => {
              this.$nextTick(() => {
                this.$store.commit('user/setAppDispProperty', {
                  applicantId: Array.isArray(targetIds) ? targetIds[0] : targetIds,
                  tab: 'files',
                  showDialog: true,
                  loadingIds: []
                })
              })
            }, 1000)
          } catch (e) {
            this.useLogger(e)
          } finally {
            this.selectedUnlock = []
          }
          break
        default:
          break
      }
    },
  },
}
</script>
