<template>
  <QTabs
    v-if="options.length"
    v-model="cptValue"
    class="row"
    align="left"
    active-color="primary"
    indicator-color="primary"
    outside-arrows
    mobile-arrows
    no-caps
    dense
  >
    <QTab
      v-for="tab of options"
      :key="tab.name"
      :name="tab.name"
      :label="tab.label"
    />
  </QTabs>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    cptValue: {
      get () { return this.modelValue },
      set (value) { this.$emit('update:modelValue', value) }
    }
  },
}
</script>
