import { usePhoneValidator, useEmailValidator, useUrlValidator } from 'hc-core/mixins/validation'

export default {
  computed: {
    aboutSteps () {
      return [
        {
          index: 0,
          icon: 'uil:pen',
          field: 'name',
          fields: ['name'],
          slug: 'nom',
          focusId: 'companyName',
          component: 'TextInput',
          label: this.$t({ id: 'component.forms.companyForm.title.title' }),
          menuLabel: this.$t({ id: 'component.forms.companyForm.menu.title' })
        },
        {
          index: 1,
          icon: 'uil:phone-alt',
          field: 'metadata._public.phone',
          fields: ['metadata._public.phone'],
          slug: 'telephone',
          focusId: 'companyPhone',
          optional: true,
          unmasked: true,
          component: 'TextInput',
          mask: '## ## ## ## ##',
          rules: [usePhoneValidator],
          label: this.$t({ id: 'pages.informations.field_phone' }),
          menuLabel: this.$t({ id: 'pages.informations.field_phone' })
        },
        {
          index: 2,
          icon: 'uil:envelope-alt',
          field: 'metadata._public.email',
          fields: ['metadata._public.email'],
          slug: 'email',
          focusId: 'companyEmail',
          component: 'TextInput',
          rules: [useEmailValidator],
          label: this.$t({ id: 'pages.informations.field_email' }),
          menuLabel: this.$t({ id: 'pages.informations.field_email' })
        },
        {
          index: 3,
          icon: 'uil:calender',
          field: 'metadata._public.creationDate',
          fields: ['metadata._public.creationDate'],
          optional: true,
          slug: 'creation',
          focusId: 'companyCreationDate',
          component: 'DateSelect',
          format: 'YYYY',
          label: this.$t({ id: 'component.forms.companyForm.creationDate.title' }),
          menuLabel: this.$t({ id: 'component.forms.companyForm.menu.creationDate' })
        },
        {
          index: 4,
          icon: 'uil:megaphone',
          field: 'metadata._public.tagline',
          fields: ['metadata._public.tagline'],
          focusId: 'companyTagline',
          slug: 'tagline',
          counter: true,
          optional: true, // Allow to let field empty
          maxlength: 100,
          component: 'TextInput',
          label: this.$t({ id: 'pages.informations.field_tagline' }),
          menuLabel: this.$t({ id: 'pages.informations.field_tagline' })
        },
        {
          index: 5,
          icon: 'uil:file-alt',
          field: 'description',
          fields: ['description'],
          focusId: 'companyDescription',
          slug: 'description',
          counter: true,
          optional: true, // Allow to let field empty
          maxlength: 800,
          type: 'textarea',
          component: 'TextInput',
          label: this.$t({ id: 'pages.informations.field_description' }),
          menuLabel: this.$t({ id: 'pages.informations.field_description' })
        },
      ]
    },
    quoteSteps () {
      return [
        {
          index: 6,
          icon: 'uil:comment-heart',
          field: 'metadata._public.quote.content',
          fields: ['metadata._public.quote.content'],
          focusId: 'companyQuote',
          slug: 'quote-content',
          component: 'TextInput',
          counter: true,
          maxlength: 250,
          optional: true, // Allow to let field empty
          type: 'textarea',
          label: this.$t({ id: 'pages.informations.field_quote' }),
          menuLabel: this.$t({ id: 'pages.informations.field_quote' })
        },
        {
          index: 7,
          icon: 'uil:chat-bubble-user',
          field: 'metadata._public.quote.author',
          fields: ['metadata._public.quote.author'],
          focusId: 'companyQuote',
          slug: 'quote-author',
          optional: true, // Allow to let field empty
          component: 'TextInput',
          label: this.$t({ id: 'pages.informations.field_quote_author' }),
          menuLabel: this.$t({ id: 'pages.informations.field_quote_author' })
        },
      ]
    },
    teamSteps () {
      return [
        {
          index: 8,
          field: 'metadata._team',
          label: 'company.headcount',
          component: 'HeadcountInput',
          optional: true,

          icon: 'uil:brackets-curly',
          focusId: 'teamHeadcount',
          slug: 'headcount-range',
          fields: ['metadata._team.headcountRange', 'metadata._team.headcountPrecision', 'metadata._team.headcount'],
        },
        // {
        //   index: 8,
        //   icon: 'uil:brackets-curly',
        //   field: 'metadata._team.headcountRange',
        //   fields: ['metadata._team.headcountRange'],
        //   focusId: 'teamHeadcount',
        //   slug: 'headcount-range',
        //   component: 'SelectInput',
        //   type: 'select',
        //   if: !this.$_.get(this.userCompany, 'metadata._team.headcountPrecision', false),
        //   options: [ // TODO : check is replacable with getCustomAttributeValues
        //     { label: this.$t({ id: 'component.selectors.headcount_range_selector.0_100' }), value: '0_100' },
        //     { label: this.$t({ id: 'component.selectors.headcount_range_selector.101_150' }), value: '101_150' },
        //     { label: this.$t({ id: 'component.selectors.headcount_range_selector.151_200' }), value: '151_200' },
        //     { label: this.$t({ id: 'component.selectors.headcount_range_selector.201_250' }), value: '201_250' },
        //     { label: this.$t({ id: 'component.selectors.headcount_range_selector.251_300' }), value: '251_300' },
        //     { label: this.$t({ id: 'component.selectors.headcount_range_selector.301_350' }), value: '301_350' },
        //     { label: this.$t({ id: 'component.selectors.headcount_range_selector.351_400' }), value: '351_400' },
        //     { label: this.$t({ id: 'component.selectors.headcount_range_selector.401_450' }), value: '401_450' },
        //     { label: this.$t({ id: 'component.selectors.headcount_range_selector.451_500' }), value: '451_500' },
        //     { label: this.$t({ id: 'component.selectors.headcount_range_selector.501_550' }), value: '501_550' },
        //     { label: this.$t({ id: 'component.selectors.headcount_range_selector.551+' }), value: '551+' },
        //   ],
        //   label: this.$t({ id: 'pages.informations.field_headcount_range' }),
        //   menuLabel: this.$t({ id: 'pages.informations.field_headcount_range' })
        // },
        // {
        //   index: 9,
        //   icon: 'uil:bullseye',
        //   field: 'metadata._team.headcount',
        //   fields: ['metadata._team.headcount'],
        //   focusId: 'teamHeadcount',
        //   slug: 'headcount-precision',
        //   component: 'TextInput',
        //   type: 'number',
        //   if: this.$_.get(this.userCompany, 'metadata._team.headcountPrecision', false),
        //   label: this.$t({ id: 'pages.informations.field_headcount' }),
        //   menuLabel: this.$t({ id: 'pages.informations.field_headcount' })
        // },
        // {
        //   index: 10,
        //   icon: 'uil:lightbulb-alt',
        //   field: 'metadata._team.headcountPrecision',
        //   fields: ['metadata._team.headcountPrecision'],
        //   focusId: 'teamHeadcount',
        //   slug: 'headcount-precision',
        //   component: 'CheckboxInput',
        //   default: false,
        //   type: 'boolean',
        //   displayLabel: {
        //     true: 'Je connais le nombre précis de collaborateurs.',
        //     false: 'Je ne connais pas le nombre précis de collaborateurs.',
        //   },
        //   label: this.$t({ id: 'pages.informations.field_headcount_precision' }),
        //   menuLabel: this.$t({ id: 'pages.informations.field_headcount_precision' })
        // },
        {
          index: 11,
          icon: 'uil:gift',
          field: 'metadata._team.ageAverage',
          fields: ['metadata._team.ageAverage'],
          focusId: 'teamAgeAverage',
          slug: 'age-average',
          optional: true, // Allow to let field empty
          unmasked: true,
          component: 'TextInput',
          mask: '##',
          label: this.$t({ id: 'pages.informations.field_age_average' }),
          menuLabel: this.$t({ id: 'pages.informations.field_age_average' })
        },
        {
          index: 12,
          icon: 'uil:yin-yang',
          field: 'metadata._team.parity',
          fields: ['metadata._team.parity'],
          focusId: 'teamParity',
          slug: 'parity',
          component: 'SliderInput',
          label: 'Parité',
          menuLabel: 'Parité',
          default: 50,
          sliders: [
            { color: 'red-4', label: this.$t({ id: 'pages.informations.field_parity_female' }), min: 0, max: 100 },
            { color: 'blue-4', label: this.$t({ id: 'pages.informations.field_parity_male' }), min: 0, max: 100, reversed: true },
          ]
        },
      ].filter((step) => this.$_.get(step, 'if', true))
    },
    linksSteps () {
      return [
        {
          index: 13,
          icon: 'uil:globe',
          field: 'metadata._public.links.website',
          fields: ['metadata._public.links.website'],
          focusId: 'companyLinksWebsite',
          slug: 'link-website',
          optional: true, // Allow to let field empty
          component: 'TextInput',
          rules: [useUrlValidator],
          label: this.$t({ id: 'pages.informations.field_website' }),
          menuLabel: this.$t({ id: 'pages.informations.field_website' })
        },
        {
          index: 14,
          icon: 'uil:linkedin-alt',
          field: 'metadata._public.links.linkedin',
          fields: ['metadata._public.links.linkedin'],
          focusId: 'companyLinksLinkedin',
          slug: 'link-linkedin',
          optional: true, // Allow to let field empty
          component: 'TextInput',
          rules: [useUrlValidator],
          label: this.$t({ id: 'pages.informations.field_linkedin' }),
          menuLabel: this.$t({ id: 'pages.informations.field_linkedin' })
        },
        {
          index: 15,
          icon: 'uil:twitter-alt',
          field: 'metadata._public.links.twitter',
          fields: ['metadata._public.links.twitter'],
          focusId: 'companyLinksTwitter',
          slug: 'link-twitter',
          optional: true, // Allow to let field empty
          component: 'TextInput',
          rules: [useUrlValidator],
          label: this.$t({ id: 'pages.informations.field_twitter' }),
          menuLabel: this.$t({ id: 'pages.informations.field_twitter' })
        },
        {
          index: 16,
          icon: 'uil:camera',
          field: 'metadata._public.links.instagram',
          fields: ['metadata._public.links.instagram'],
          focusId: 'companyLinksInstagram',
          slug: 'link-instagram',
          optional: true, // Allow to let field empty
          component: 'TextInput',
          rules: [useUrlValidator],
          label: this.$t({ id: 'pages.informations.field_instagram' }),
          menuLabel: this.$t({ id: 'pages.informations.field_instagram' })
        },
        {
          index: 17,
          icon: 'uil:youtube',
          field: 'metadata._public.links.youtube',
          fields: ['metadata._public.links.youtube'],
          focusId: 'companyLinksYoutube',
          slug: 'link-youtube',
          optional: true, // Allow to let field empty
          component: 'TextInput',
          rules: [useUrlValidator],
          label: this.$t({ id: 'pages.informations.field_youtube' }),
          menuLabel: this.$t({ id: 'pages.informations.field_youtube' })
        },
        {
          index: 18,
          icon: 'uil:facebook-f',
          field: 'metadata._public.links.facebook',
          fields: ['metadata._public.links.facebook'],
          focusId: 'companyLinksFacebook',
          slug: 'link-facebook',
          optional: true, // Allow to let field empty
          component: 'TextInput',
          rules: [useUrlValidator],
          label: this.$t({ id: 'pages.informations.field_facebook' }),
          menuLabel: this.$t({ id: 'pages.informations.field_facebook' })
        },
      ]
    },
    logoSteps () {
      return [
        {
          index: 19,
          icon: 'uil:image-plus',
          field: 'metadata._files.logo',
          fields: ['metadata._files.logo'],
          focusId: 'companyLogo',
          slug: 'files-logo',
          type: 'img',
          optional: true, // Allow to let field empty
          component: 'ImageCropperUploader',
          label: this.$t({ id: 'pages.medias.logo' }),
          menuLabel: this.$t({ id: 'pages.medias.logo' }),
          // Img parameters
          isCircle: true,
          prefix: 'logo',
          useEntityId: true,
          uploadFolder: 'assets/logos',
          size: { width: '16rem', height: '16rem' },
          hint: 'Cliquez ou glissez votre logo ici',
          key: 'metadata._files.logo', // Consider dump it
          value: this.$_.get(this.userCompany, 'metadata._files.logo'), // Consider dump it
        },
      ]
    },
    coverSteps () {
      return [
        {
          index: 20,
          icon: 'uil:image-plus',
          field: 'metadata._files.cover',
          fields: ['metadata._files.cover'],
          focusId: 'companyCover',
          slug: 'files-cover',
          type: 'img',
          optional: true, // Allow to let field empty
          component: 'ImageCropperUploader',
          label: this.$t({ id: 'pages.medias.cover' }),
          menuLabel: this.$t({ id: 'pages.medias.cover' }),
          // Img parameters
          prefix: 'cover',
          useEntityId: true,
          uploadFolder: 'assets/covers',
          size: { width: '100%', height: '300px' },
          hint: 'Cliquez ou glissez votre photo de couverture ici',
          key: 'metadata._files.cover', // Consider dump it
          value: this.$_.get(this.userCompany, 'metadata._files.cover'), // Consider dump it
        },
      ]
    },
    albumsSteps () {
      return [
        {
          index: 21,
          icon: 'uil:gift',
          field: 'metadata._files.albums',
          fields: ['metadata._files.albums'],
          focusId: 'companyAlbums',
          type: 'gallery',
          slug: 'albums',
          // component: 'GridMediaManager',
          optional: true, // Allow to let field empty
          label: this.$t({ id: 'pages.medias.gallery' }),
          menuLabel: this.$t({ id: 'pages.medias.gallery' })
        },
      ]
    },
    advantagesSteps () {
      return [
        {
          index: 22,
          icon: 'uil:gift',
          field: 'metadata._team.advantages',
          fields: ['metadata._team.advantages'],
          focusId: 'companyAdvantages',
          type: 'array',
          slug: 'advantages',
          component: 'AdvantagesInput',
          optional: true, // Allow to let field empty
          label: this.$t({ id: 'pages.advantages.title' }),
          menuLabel: this.$t({ id: 'pages.advantages.title' })
        },
      ]
    },

    stepsData () {
      // TODO: Mega supra dupa merge of everything
      return this.$_.orderBy(
        this.$_.concat(
          this.aboutSteps,
          this.quoteSteps,
          this.teamSteps,
          this.linksSteps,
          this.logoSteps,
          this.coverSteps,
          this.albumsSteps,
          this.advantagesSteps,
        ),
        ['index'],
        ['desc']
      )
    }
  }
}
