<template>
  <div
    v-if="company"
    ref="CompanyPage"
    class="q-pa-md"
  >
    <div
      id="masonryContainer"
      ref="masonryContainer"
      class="row q-col-gutter-lg"
      transition-duration="0.3s"
      item-selector=".item"
    >
      <template
        v-for="(block, i) of companyBlocks"
        :key="i"
      >
        <div
          v-if="!block.customComponent"
          :class="wrapClass"
        >
          <QCard :class="$_.get(block, 'cardClass', '')">
            <!-- Description -->
            <template v-if="block.slug === 'description'">
              <QCardSection>
                <AppContent
                  class="text-h6 text-grey-4"
                  path="component.company.description.title"
                />
                <div
                  v-if="company.description && $_.get(company, 'description', '').length > 0"
                  class="preserve-lines text-body-2"
                >
                  {{ company.description }}
                </div>
                <AppContent
                  v-else
                  path="component.build_parts.company_description.not_filled"
                  :options="{ name: company.name }"
                />
              </QCardSection>
            </template>

            <!-- Numbers & links -->
            <template v-if="block.slug === 'numbers'">
              <QList separator>
                <QItem
                  v-for="(data, iData) of block.content"
                  :key="iData"
                >
                  <QItemSection avatar>
                    <QIcon
                      color="grey-4"
                      :name="`uil:${data.icon}`"
                    />
                  </QItemSection>
                  <QItemSection>
                    <QItemLabel
                      caption
                      lines="1"
                    >
                      {{ data.label }}
                    </QItemLabel>
                    <QItemLabel
                      v-if="data.value"
                      class="text-weight-medium"
                    >
                      {{ data.value }}
                    </QItemLabel>
                    <QItemLabel v-else-if="data.icon === 'link'">
                      <div class="flex items-center">
                        <a
                          v-for="(link, iLink) of mappedLinks"
                          :key="iLink"
                          :href="link.escaped"
                          target="_blank"
                          referrerpolicy="no-referrer"
                          class="q-mr-md"
                        >
                          <QAvatar
                            size="2.3rem"
                            :class="link.class"
                            :icon="link.icon"
                          />
                        </a>
                      </div>
                    </QItemLabel>
                  </QItemSection>
                </QItem>
              </QList>
            </template>

            <!-- Advantages -->
            <template v-if="block.slug === 'advantages'">
              <QCardSection class="justify-center column flex">
                <AppContent
                  class="text-h6 text-grey-4"
                  path="component.build_parts.company_advantages.title"
                />
                <QList>
                  <QItem
                    v-for="(advantage, index) of company.metadata._team.advantages"
                    :key="index"
                    class="q-px-xs"
                  >
                    <QItemSection
                      side
                      class="flex text-h5"
                    >
                      {{ hexToEmo(advantage.emoji) }}
                    </QItemSection>
                    <QItemSection class="text-body2 text-grey-6">
                      {{ advantage.text }}
                    </QItemSection>
                  </QItem>
                </QList>
              </QCardSection>
            </template>

            <!-- Offers -->
            <template v-if="block.slug === 'offers'">
              <QCardSection>
                <div class="flex justify-between">
                  <AppContent
                    class="text-h6 text-grey-4"
                    path="component.build_parts.company_offers.title"
                  />
                  <div
                    v-if="offers.length > 0"
                    class="flex items-center text-primary"
                  >
                    <QSpinnerRings
                      color="negative"
                      size="sm"
                      class="q-mr-sm"
                    />
                    <AppContent
                      path="component.build_parts.company_offers.running_offers"
                      :options="{ nb: offers.length, name: company.name }"
                    />
                  </div>
                </div>
                <div
                  v-if="offers.length > 0"
                  class="row q-mt-xs q-col-gutter-md"
                >
                  <div
                    v-for="(asset, index) of offers.slice(0, 3)"
                    :key="index"
                    class="col-12"
                  >
                    <OfferCard
                      :company="company"
                      :asset="asset"
                      :mini="true"
                    />
                  </div>
                </div>
                <AppContent
                  v-else
                  class="q-pt-md"
                  path="component.build_parts.company_offers.running_offers"
                  :options="{ nb: 0, name: company.name }"
                />
                <div class="flex items-center justify-center q-mt-md">
                  <HCBigButton
                    v-if="offers.length > 0"
                    color="secondary"
                    class="text-white"
                    label="component.build_parts.company_offers.see_all"
                    :to="{ name:'companyOffers', params: { slug: $_.get(company, 'customAttributes.slug', false) }}"
                  />
                </div>

                <QSeparator
                  v-if="block.hasSpontaneous"
                  class="q-my-sm"
                />
                <div
                  v-if="block.hasSpontaneous"
                  class="flex column items-center"
                >
                  <AppContent
                    path="component.company.default_application.no_offer"
                    class="text-h6 q-mb-sm text-weight-bolder"
                  />
                  <AppContent
                    path="component.company.default_application.spontaneous"
                    class="text-subtitle2 q-mb-sm"
                  />
                  <HCBigButton
                    color="secondary"
                    label="pages.company_offers.wanna_join"
                    @click="applyDialog = true"
                  />
                  <ApplyDialog
                    v-model="applyDialog"
                    :trn-target="company"
                    @hide="applyDialog = false"
                  />
                </div>
              </QCardSection>
            </template>

            <!-- Tools -->
            <template v-if="block.slug === 'tools'">
              <QCardSection>
                <AppContent
                  class="text-h6 text-grey-4"
                  path="component.company.tools.title"
                />
                <QList separator>
                  <QItem
                    v-for="(toolsPair, iToolsPair) of $_.chunk(allTools, 2)"
                    :key="iToolsPair"
                  >
                    <template
                      v-for="(tool, iTool) of toolsPair"
                      :key="iTool"
                    >
                      <template v-if="translationExists(`customAttributes.tools.${tool}`)">
                        <QItemSection avatar>
                          <img
                            class="cursor-pointer"
                            :src="cdnImg(`assets/attributes/tools/${tool}.png`, { quality: 75 })"
                            loading="lazy"
                            height="40"
                            alt="i"
                            style="background-color:white;"
                            @click="openTool(tool)"
                          >
                        </QItemSection>
                        <QItemSection :class="{ 'q-ml-md': iTool }">
                          <QItemLabel
                            class="cursor-pointer"
                            @click="openTool(tool)"
                          >
                            {{ $t({ id: `customAttributes.tools.${tool}` }) }}
                          </QItemLabel>
                        </QItemSection>
                      </template>
                      <template v-else>
                        <QItemSection avatar>
                          <QIcon
                            color="primary"
                            name="uil:wrench"
                          />
                        </QItemSection>
                        <QItemSection :class="{ 'q-ml-md': iTool }">
                          <QItemLabel>
                            {{ tool }}
                          </QItemLabel>
                        </QItemSection>
                      </template>
                      <QSeparator
                        v-if="iTool === 0 && iTool !== toolsPair.length - 1"
                        vertical
                      />
                    </template>
                  </QItem>
                </QList>
              </QCardSection>
            </template>

            <!-- Quote -->
            <template v-if="block.slug === 'quote'">
              <div class="full-height justify-center flex column text-center q-pa-md">
                <div
                  v-if="$_.get(company, 'metadata._public.quote.content', false)"
                  class="text-h6 text-weight-medium"
                >
                  "{{ company.metadata._public.quote.content }}"
                </div>
                <div
                  v-if="$_.get(company, 'metadata._public.quote.author', false)"
                  class="text-body2-bold text-grey-5 q-mt-sm"
                >
                  {{ company.metadata._public.quote.author }}
                </div>
              </div>
            </template>

            <!-- Testimonials -->
            <template v-if="block.slug === 'testimonials'">
              <QCardSection
                v-if="testimonials.length > 0"
                class="flex justify-between items-center q-pb-none"
              >
                <div class="flex items-center">
                  <AppContent
                    class="text-h6 text-grey-4"
                    path="component.company.members.title"
                  />
                  <div class="q-ml-md text-caption">
                    {{ slideTeam + 1 }} / {{ testimonials.length }}
                  </div>
                </div>
                <div
                  class="flex items-center"
                  style="font-size: 26px"
                >
                  <QBtn
                    v-if="slideTeam > 0 && slideTeam < testimonials.length"
                    unelevated
                    dense
                    icon="uil:angle-left"
                    @click="slideTeam--"
                  />
                  <QBtn
                    v-if="slideTeam < testimonials.length-1"
                    unelevated
                    dense
                    icon="uil:angle-right"
                    @click="slideTeam++"
                  />
                </div>
              </QCardSection>
              <QCardSection
                v-if="testimonials.length > 0"
                class="q-pt-none"
              >
                <QCarousel
                  v-model="slideTeam"
                  animated
                  style="height: 400px;"
                >
                  <QCarouselSlide
                    v-for="(testimonial, index) of testimonials"
                    :key="`testimonial_${index}`"
                    :name="index"
                    class="q-pa-none"
                  >
                    <QScrollArea style="height: 400px;">
                      <div class="q-pr-sm">
                        <TestimonialCard :asset="testimonial" />
                      </div>
                    </QScrollArea>
                  </QCarouselSlide>
                </QCarousel>
              </QCardSection>
            </template>
          </QCard>
        </div>

        <!-- Medias -->
        <template v-if="block.slug === 'medias'">
          <div
            v-for="(album, albumIndex) of company.metadata._files.albums"
            :key="albumIndex"
            :class="wrapClass"
          >
            <QCard
              v-if="album !== null && album.type === 'album'"
              class="q-mb-md full-height br-25 hc-shadow"
            >
              <QCardSection class="text-h6 text-grey-4">
                {{ album.name }}
                <QScrollArea style="height: 301px;">
                  <div class="flex q-gutter-sm justify-start">
                    <template
                      v-for="(file, index) of $_.compact(album.files)"
                      :key="index"
                    >
                      <div
                        class="relative-position"
                        square
                        :style="$q.screen.lt.md ? 'width:calc(50% - 8px)' : 'width:calc(33% - 8px)'"
                        @click="openImage(album, index)"
                      >
                        <QImg
                          v-if="file.type === 'video'"
                          class="br-25 cursor-pointer hc-thumbnail dimmed"
                          :ratio="1"
                          style="object-fit:cover;"
                          :src="'https://img.youtube.com/vi/' + youtubeParser(file.url) + '/0.jpg'"
                          loading="lazy"
                          alt="i"
                        />
                        <QImg
                          v-else
                          class="br-25 cursor-pointer hc-thumbnail dimmed"
                          :ratio="1"
                          style="object-fit:cover;"
                          :src="cdnImg(file.key, { width: 150 })"
                          loading="lazy"
                          alt="i"
                        />
                        <QIcon
                          v-if="file.type === 'video'"
                          style="pointer-events:none;"
                          class="br-25 absolute-center"
                          name="uil:play-circle"
                          color="white"
                          size="md"
                        />
                      </div>
                    </template>
                  </div>
                </QScrollArea>
              </QCardSection>
            </QCard>
            <QCard
              v-if="album !== null && (album.type === 'image' || album.type === undefined)"
              class="q-mb-md full-height cursor-pointer br-25 overflow-hidden hc-shadow"
            >
              <QImg
                :src="cdnImg(album.key, { width: 500 })"
                :style="album.caption === '' ? 'height:100%;' : 'height:342px;'"
                loading="lazy"
                alt="i"
                @click="openImage(album)"
              />
              <QSeparator v-if="album.caption" />
              <QCardSection
                v-if="album.caption"
                class="q-pa-md"
              >
                <div class="text-body1 text-semibold items-center full-width">
                  {{ $_.truncate(album.caption, { length: 70 }) }}
                  <ActionTooltip :str="album.caption" />
                </div>
              </QCardSection>
            </QCard>
            <QCard
              v-else-if="album !== null && (album.type === 'video' || album.type === undefined)"
              class="full-height br-25 hc-shadow"
            >
              <LazyYtVideo
                :src="embedURL(album.url)"
                classes="br-25-top"
              />
              <QSeparator v-if="album.caption" />
              <QCardSection
                v-if="album.caption"
                class="q-pa-md"
              >
                <div
                  class="text-body1 text-semibold flex column items-center text-center flex-center"
                  style="height:48px;"
                >
                  {{ $_.truncate(album.caption, { length: 70 }) }}
                  <ActionTooltip :str="album.caption" />
                </div>
              </QCardSection>
            </QCard>
          </div>
          <QDialog
            v-model="albumViewer"
            @hide="albumViewer = false"
          >
            <QCard
              ref="albumDialog"
              class="bg-grey-3"
            >
              <QCardSection class="row items-center fit">
                <QCarousel
                  v-if="currentAlbum !== null && $_.has(currentAlbum, 'files')"
                  v-model="slide"
                  :height="(0.8*cardHeight)+'px'"
                  class="full-width bg-grey-3 text-primary"
                  navigation
                  animated
                  infinite
                  arrows
                  padding
                >
                  <QCarouselSlide
                    v-for="(file, index) of currentAlbum.files"
                    :key="index"
                    :name="index"
                  >
                    <QImg
                      v-if="file.type === 'image'"
                      class="bg-grey-3"
                      :src="cdnImg(file.key, { width: 1920 })"
                      :style="`max-width: 80vw; max-height: 90vh; width:2500px; height: ${0.77*cardHeight}px;`"
                      fit="scale-down"
                      loading="lazy"
                      alt="i"
                    >
                      <div
                        v-if="file.caption"
                        class="absolute-top text-subtitle1 text-center"
                      >
                        {{ file.caption }}
                      </div>
                    </QImg>
                    <LazyYtVideo
                      v-if="file.type === 'video'"
                      :src="embedURL(file.url)"
                      :style="`max-width: 80vw; max-height: 90vh; width:2500px; height: ${0.77*cardHeight}px;`"
                    />
                  </QCarouselSlide>
                </QCarousel>
                <QImg
                  v-if="currentAlbum !== null && !$_.has(currentAlbum, 'files')"
                  :src="cdnImg(currentAlbum.key, { width: 1920 })"
                  style="max-width: 90vw; max-height: 90vh; width:2500px;"
                  fit="scale-down"
                  loading="lazy"
                  alt="i"
                >
                  <div
                    v-if="currentAlbum.caption"
                    class="absolute-top text-subtitle1 text-center"
                  >
                    {{ currentAlbum.caption }}
                  </div>
                </QImg>
              </QCardSection>
            </QCard>
          </QDialog>
        </template>

        <!-- Entries -->
        <template
          v-if="block.slug === 'entries'"
          class="q-gutter-md"
        >
          <div
            v-for="entry of companyEntries"
            :key="entry.id"
            :class="wrapClass"
          >
            <component
              :is="componentType[entry.collection]"
              :article="entry"
            />
          </div>
        </template>
      </template>
    </div>
  </div>
  <div
    v-if="isBlocVisible('map') && activeLocations.length"
    class="full-width q-mt-md"
  >
    <Map
      :options="mapOptions"
      :pins="activeLocations"
      :height="400"
    />
  </div>
</template>

<script>
import Map from 'hc-core/components/tools/map'
import imagesLoaded from 'imagesloaded'
import { defineAsyncComponent } from 'vue'
import OfferCard from 'hc-core/components/cards/offer-card'
import ArticleCard from 'hc-core/components/cards/article-card'
import LazyYtVideo from 'hc-core/components/tools/lazy-yt-video'
import TestimonialCard from 'hc-core/components/cards/testimonial-card'
import { embedURL, escapeUrl, isDefined, hexToEmo, youtubeParser, companyTeamNumber } from 'hc-core/composables/misc.js'

// Too much dependencies on applyDialog, so we only import it in WEB context.
// In APP, set the component to null so it does nothing
const ApplyDialog = process.env.HC_PLATFORM === 'WEB' ? defineAsyncComponent(() =>
  import('hc-core/components/dialogs/apply-dialog')
) : null

export default {
  components: { Map, OfferCard, ArticleCard, LazyYtVideo, ApplyDialog, TestimonialCard },
  props: {
    company: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
      msnry: null,
      componentType: {
        blog: 'ArticleCard',
        'blog-vdc': 'ArticleCard',
        podcasts: 'PodcastCard',
        capsules: 'CapsuleCard',
      },
      mapOptions: { container: 'profileMap', center: [3, 46.27], zoom: 5, minZoom: 2, maxZoom: 15 },

      // NEW
      wrapClass: 'item col-12 col-sm-6 col-md-4 col-lg-3',
      socialLinks: ['facebook', 'youtube', 'instagram', 'linkedin', 'twitter', 'website'],
      socialIcons: {
        facebook: 'uil:facebook-f',
        youtube: 'uil:youtube',
        instagram: 'uil:instagram',
        linkedin: 'uil:linkedin-alt',
        twitter: 'uil:twitter',
        website: 'uil:window'
      },
      slideTeam: 0,
      companyEntries: [],
      applyDialog: false,
      computedOnceNb: false,
      // Gallery
      albumViewer: false,
      currentAlbum: null,
      slide: 0,
      cardWidth: 0,
      cardHeight: 0,
      aspectRatio: 0,
    }
  },
  computed: {
    isGroup () { return this.$store.getters['asset/isGroup'] },
    isBrand () { return this.company.assetTypeId === this.$store.getters['common/brandTypeId'] },
    activeLocations () {
      return this.$_.union(
        this.$_.get(this.$store, 'getters["asset/activeLocations"]', []),
        process.env.HC_PLATFORM === 'APP' ? this.$_.flatten(this.$uElements('companies').map(c => c.locations)) : []
      )
    },
    // companyEntries () { return this.$store.getters['asset/companyEntries'] },
    testimonials () {
      return this.$_.concat(
        this.$_.get(this.$store, 'getters["rating/testimonials"]', []),
        this.$uElements('ratings'),
      ).filter(rtg => rtg.metadata.active && rtg.score === 100 && rtg.assetId === this.company.id)
    },
    hasLinks () {
      let hasLink = false
      if (Array.isArray(this.$_.get(this.company, 'metadata._public.links', false))) {
        this.company.metadata._public.links.forEach((link) => {
          if (link && link.url && link.url.length) hasLink = true
        })
      } else if (Object.keys(this.$_.get(this.company, 'metadata._public.links', false)).length) {
        Object.keys(this.company.metadata._public.links).forEach((key) => {
          if (this.$_.get(this.company, `metadata._public.links.${key}`)) hasLink = true
        })
      }
      return hasLink
    },

    // NEW
    year () {
      return this.$_.get(this.company, 'metadata._public.creationDate', false) ? new Date(this.company.metadata._public.creationDate).getFullYear() : 'default'
    },
    offers () {
      let offers = []
      const tmpOffers = this.$_.union(
        this.$_.get(this.company, 'offers', []),
        this.$uElements('offers'),
        this.$_.get(this.$store, 'getters["asset/companyOffers"]', []),
      ).filter(o => o.active && o.validated)

      if (this.isBrand && this.userOrganizations && this.userOrganizations.implantations) offers = this.$_.flatten(this.$_.compact(this.userOrganizations.implantations.map(imp => imp.offers ?? null)))
      else offers = tmpOffers

      if (this.$_.get(this.company, 'platformData.options.checkOffersLocations')) {
        offers = offers.filter(offer => offer.categoryId !== this.$store.getters['common/defaultOfferCategoryId'] && this.$_.intersection(this.company.locations.map(loc => loc.id), offer.locations.map(loc => loc.id)).length > 0)
      } else {
        offers = offers.filter(offer => offer.categoryId !== this.$store.getters['common/defaultOfferCategoryId'])
      }
      return this.$_.orderBy(offers, ['platformData.published', 'createdDate'], ['desc', 'desc'])
    },
    parityRates () {
      let { womanRate, manRate } = '-'
      if (this.company.metadata._team) {
        if (this.company.metadata._team.parity) { // Has filled these fields in v2
          womanRate = Math.round(this.company.metadata._team.parity)
          manRate = 100 - Math.round(this.company.metadata._team.parity)
        } else { // Just imported from v1
          if (this.company.metadata._team.womanQty && this.company.metadata._team.manQty) {
            const totalPersons = parseInt(this.company.metadata._team.womanQty) + parseInt(this.company.metadata._team.manQty)
            womanRate = Math.round(parseInt(this.company.metadata._team.womanQty) / totalPersons * 100)
            manRate = 100 - womanRate
          }
        }
      }
      return { womanRate, manRate }
    },
    mappedLinks () {
      return this.$_.compact(Object.entries(this.$_.get(this.company, 'metadata._public.links', {})).map(([key, value]) => {
        const escaped = escapeUrl(value)
        if (escaped) {
          return {
            escaped,
            icon: this.socialIcons[key],
            class: `text-white bg-${key}`
          }
        } else return null
      }))
    },

    allTools () {
      const caTools = this.$_.get(this.company, 'customAttributes.tools', [])
      const customTools = this.$_.get(this.company, 'customAttributes.tools_custom', '').split(' ').map(t => t.replaceAll('_', ' '))
      return this.$_.compact(this.$_.union(caTools, customTools))
    },

    // ULTRA NEW
    companyBlocks () {
      const numbersContent = [
        { icon: 'calender', label: this.$t({ id: 'component.company.creation.label' }), value: this.year, if: !isNaN(Date.parse(this.$_.get(this.company, 'metadata._public.creationDate', false))), },
        { icon: 'percentage', label: this.$t({ id: 'component.company.parity.title' }), value: `${this.parityRates.womanRate}% ${this.$t({ id: 'component.company.parity.women' })} / ${this.parityRates.manRate}% ${this.$t({ id: 'component.company.parity.men' })}`, if: this.$_.get(this.company, 'metadata._team.parity', false) || (this.$_.get(this.company, 'metadata._team.womanQty', false) && this.$_.get(this.company, 'metadata._team.manQty', false)), },
        { icon: 'users-alt', label: this.$t({ id: 'component.company.team.title' }), value: `${this.computedOnceNb} ${this.$t({ id: 'component.company.team.subtitle' })}`, if: !!this.computedOnceNb },
        { icon: 'clock', label: this.$t({ id: 'component.company.age_average.title' }), value: `${this.$_.get(this.company, 'metadata._team.ageAverage', false)} ${this.$t({ id: 'component.company.age_average.suffix' })}`, if: this.$_.get(this.company, 'metadata._team.ageAverage', false) },
        { icon: 'link', label: this.$t({ id: 'component.company.social_links.title' }), if: this.mappedLinks.length },
      ].filter(c => this.$_.get(c, 'if', true))

      return [
        {
          slug: 'description',
        },
        {
          slug: 'numbers',
          content: numbersContent,
          if: numbersContent.length
        },
        {
          slug: 'offers',
          hasSpontaneous: this.isBlocVisible('spontaneous')
        },
        {
          slug: 'advantages',
          if: this.$_.get(this.company, 'metadata._team.advantages', false) && this.$_.get(this.company, 'metadata._team.advantages', []).length > 0,
        },
        {
          slug: 'testimonials',
          if: this.testimonials.length > 0,
        },
        {
          slug: 'tools',
          if: this.allTools.length > 0,
        },
        {
          slug: 'quote',
          cardClass: 'column items-center',
          if: !!this.$_.get(this.company, 'metadata._public.quote.content', false),
        },
        {
          slug: 'medias',
          if: this.$_.get(this.company, 'metadata._files.albums', []).length,
          customComponent: true
        },
        {
          slug: 'entries',
          if: this.$_.get(this.company, 'metadata._public.entries', []).length && this.$_.get(this, 'companyEntries', []).length,
          customComponent: true
        },
        {
          slug: 'map',
          if: this.activeLocations.length,
          customComponent: true
        },
      ].map((b) => {
        this.$_.set(b, 'cardClass', this.$_.get(b, 'cardClass', '') + 'hc-shadow br-25')
        return b
      }).filter(b => this.isBlocVisible(b.slug) && this.$_.get(b, 'if', true))
    },
  },
  watch: {
    company: {
      deep: true,
      immediate: true,
      handler: async function (val) {
        if (val) {
          // Async fetching
          this.companyEntries = []
          if (this.$_.get(this.company, 'metadata._public.entries', []).length) {
            this.companyEntries = await this.$store.dispatch('content/list', { id: this.$_.get(this.company, 'metadata._public.entries', []) })
          }
          await this.computeTeamNb()
          this.$nextTick(() => { this.setupMasonry() })
        }
      }
    },
    slide: {
      handler: function (newVal, oldVal) { this.dialogCardStyle() }
    }
  },
  async created () {
    await this.computeTeamNb()
  },
  methods: {
    embedURL,
    escapeUrl,
    hexToEmo,
    youtubeParser,
    isBlocVisible (bloc) { return this.$_.get(this.company, `metadata.pageOptions.blocs.${bloc}`, true) },
    setupMasonry () {
      if (process.env.CLIENT) {
        const displayWidth = document.getElementById('masonryContainer').clientWidth
        this.wrapClass = this.$_.get([
          { from: 0, to: 599, class: 'item col-12' },
          { from: 600, to: 1023, class: 'item col-12 col-sm-6' },
          { from: 1024, to: 1439, class: 'item col-12 col-sm-6 col-md-4' },
          { from: 1440, to: 999999, class: 'item col-12 col-sm-6 col-md-4 col-lg-3' },
        ].find(m => m.to > displayWidth && displayWidth >= m.from), 'class', 'item col-12 col-sm-6 col-md-4 col-lg-3')
        this.$nextTick(() => {
          this.msnry = new this.$Masonry('#masonryContainer', { itemSelector: '.item' })
          imagesLoaded(document.querySelector('#masonryContainer'), (instance) => { this.msnry.layout() })
        })
      }
    },
    openTool (name) {
      if (process.env.HC_PLATFORM === 'WEB') {
        window.open(this.$router.resolve({ name: 'knowledgeTools', params: { name } }).href, '_blank')
      } else {
        window.open(this.$web({ name: 'knowledgeTools', params: { name } }), '_blank')
      }
    },
    // new
    relatedCompany (offer) {
      if (!this.isBrand) return this.company
      else return this.userOrganizations.implantations.filter(imp => imp.company && imp.company.ownerId === offer.ownerId)[0].company
    },
    async computeTeamNb () {
      const tmp = await companyTeamNumber({}, { company: this.company })
      let res = false
      if (tmp) {
        if (tmp.type === 'range' && tmp.nb) res = this.$t({ id: `component.selectors.headcount_range_selector.${this.$_.get(tmp, 'nb')}` })
        else res = this.$_.get(tmp, 'nb', false)
      } else res = false
      this.computedOnceNb = isDefined(res) ? res : false
    },
    dialogCardStyle () {
      if (this.albumViewer && this.currentAlbum && process.env.CLIENT) {
        let url = ''
        let type = 'image'
        if (this.$_.has(this.currentAlbum, 'files')) {
          if (this.currentAlbum.files[this.slide].type === 'video') type = 'video'
          if (type === 'image') url = this.getWebpImageUrl(this.currentAlbum.files[this.slide].key)
          if (type === 'video') url = this.currentAlbum.files[this.slide].url
        } else {
          if (type === 'image') url = this.getWebpImageUrl(this.currentAlbum.key)
        }
        if (type === 'image') {
          const img = new Image()
          img.addEventListener('load', (e) => {
            const aspectRatio = img.naturalWidth / img.naturalHeight
            const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - 48
            let heightFinal = 0
            let widthFinal = 0
            if (aspectRatio >= 1) { // Horizontal images
              widthFinal = vh * aspectRatio
              heightFinal = widthFinal / aspectRatio
            } else { // Vertical images
              widthFinal = vw * aspectRatio
              heightFinal = widthFinal / aspectRatio
            }
            this.aspectRatio = aspectRatio
            this.cardWidth = Math.round(0.8 * widthFinal)
            this.cardHeight = Math.round(0.8 * heightFinal)
            if (this.cardWidth && this.cardHeight) {
              return 'min-width:60%; width:' + this.cardWidth + 'px; height:' + this.cardHeight + 'px'
            } else return 'min-width:60%;'
          })
          img.src = url
        } else {
          this.$nextTick(() => {
            if (this.$refs.albumDialog) {
              const height = Math.floor(this.$refs.albumDialog.$el.clientWidth * 9 / 16) + 80
              this.cardHeight = height
              return 'min-width:60%; width:' + this.$refs.albumDialog.$el.clientWidth + 'px; height:' + height + 'px'
            } else {
              return 'min-width:60%;'
            }
          })
        }
      }
      return 'min-width:60%;'
    },
    openImage (album, index) {
      this.currentAlbum = album
      this.slide = index
      this.albumViewer = true
      this.dialogCardStyle()
    }
  },
}
</script>

<style lang="sass" scoped>
.zIndex10
  z-index: 10
.hc-thumbnail
  &:after
    opacity: 0
    transition: 0.3s ease all
  &:hover:after
    opacity: 0.8
@media (min-width: 600px)
  .q-dialog__inner--minimized > div
    max-width: unset
</style>
