<template>
  <div ref="OfferReview">
    <QCheckbox v-model="multicast" color="positive" :label="$t({ id: 'component.forms.offerForm.review.checkbox' })" class="q-my-sm" :disable="readonly || !isSubscribed()" @update:model-value="stepResult()" />
    <div class="flex justify-around q-mt-none q-gutter-x-sm q-gutter-y-md position-relative">
      <QCard v-if="!isSubscribed()" bordered flat class="bg-white text-dark q-pa-sm bd-negative q-ma-none">
        <div class="flex no-wrap justify-between items-center q-gutter-x-md">
          <AppContent class="text-body2" path="component.forms.offerForm.review.only_subscribed" />
          <HCBigButton :to="{ name: 'subscription' }" color="primary" label="components.hc_button.go_subscribe" />
        </div>
      </QCard>
      <div v-for="jobboard of jobboards" :key="jobboard" class="q-pa-none bd-2 bd-grey-3 br-8 flex items-center justify-center" :style="`width:122px;height:66px;${ !isSubscribed() ? 'opacity: 0.3;': '' }`">
        <img :src="cdnImg($t({ id:`images.jobboard${jobboard}` }))" loading="lazy" style="max-width:115px;max-height:60px;">
        <ActionTooltip :str="jobboard" />
      </div>
    </div>
  </div>
</template>

<script>
import OfferMixin from 'hc-core/mixins/offer.js'

export default {
  mixins: [OfferMixin],
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult', 'goToStep'],
  data () {
    return {
      multicast: false,
      jobboards: ['Indeed', 'LinkedIn', 'Apec', 'FranceTravail', 'Talent', 'Jobijoba', 'GoogleJobs', 'OptionCarriere', 'Glassdoor', 'Adzuna', 'Jooble', 'JobRapido', 'EmploiLibre', 'AllJobs', 'LEtudiant', 'Wizbii', 'EngagementJeunes', 'LeFigaroEmploi', 'PMEJob'],
    }
  },
  created () {
    this.$nextTick(() => {
      if (this.$_.get(this, 'formObject.metadata.multicast', null)) this.multicast = this.formObject.metadata.multicast
      else if (this.isSubscribed()) this.multicast = true
      this.stepResult()
    })
  },
  methods: {
    stepResult () {
      const value = {}
      this.$_.set(value, this.step.field, this.multicast)
      this.$emit('stepResult', {
        canGoNext: this.multicast || (this.step && this.step.optional),
        value: value,
      })
    },
  },
}
</script>
