<template>
  <div ref="OfferWage" class="flex column q-gutter-y-md">
    <QSelect
      v-model="salaryType"
      :options="typeOptions"
      :readonly="readonly"
      :display-value="$t({ id: `component.forms.offerForm.wage.${salaryType}` })"
      dropdown-icon="uil:angle-down"
      style="max-width:150px"
      bg-color="grey-1"
      map-options
      emit-value
      outlined
      @update:model-value="stepResult"
    />
    <div class="flex justify-start items-center q-gutter-x-md">
      <QInput
        v-if="salaryType==='range' || salaryType==='from'"
        v-model.number="salaryMin"
        type="number"
        :readonly="readonly"
        style="max-width:150px"
        debounce="1000"
        outlined
        @update:model-value="stepResult"
      >
        <template #append>
          <QIcon name="uil:euro" color="secondary" />
        </template>
      </QInput>
      <div v-if="salaryType==='range'" class="text-body1 text-grey-4">
        et
      </div>
      <QInput
        v-if="salaryType==='range' || salaryType==='up_to' || salaryType==='exact'"
        v-model.number="salaryMax"
        type="number"
        :readonly="readonly"
        style="max-width:150px"
        debounce="1000"
        outlined
        @update:model-value="stepResult"
      >
        <template #append>
          <QIcon name="uil:euro" color="secondary" />
        </template>
      </QInput>
    </div>
    <div class="flex justify-start items-center q-gutter-x-md">
      <QSelect
        v-model="salaryTax"
        :options="moneyOptions"
        :readonly="readonly"
        :display-value="$t({ id: `component.forms.offerForm.wage.${salaryTax}` })"
        dropdown-icon="uil:angle-down"
        style="max-width:150px"
        bg-color="grey-1"
        map-options
        emit-value
        outlined
        @update:model-value="stepResult"
      />
      <QSelect
        v-model="salaryPeriod"
        :options="getCustomAttributeValues('salaryPeriod')"
        :readonly="readonly"
        :display-value="$t({ id: `customAttributes.salaryPeriod.${salaryPeriod}` })"
        dropdown-icon="uil:angle-down"
        style="max-width:150px"
        bg-color="grey-1"
        map-options
        emit-value
        outlined
        @update:model-value="stepResult"
      />
    </div>
    <QExpansionItem :label="$t({ id: 'component.forms.offerForm.wage.forcedTitle' })" icon="uil:exclamation-triangle" header-class="text-subtitle2 text-grey-7 bg-purple-2 br-30" style="max-width:460px">
      <QCard>
        <AppContent path="component.forms.offerForm.wage.forcedHint" class="text-caption text-grey-5" />
      </QCard>
    </QExpansionItem>
  </div>
</template>

<script>
export default {
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      salaryType: 'range',
      salaryMin: null,
      salaryMax: null,
      salaryTax: 'gross',
      salaryPeriod: 'yearly',
      typeOptions: [
        { label: this.$t({ id: 'component.forms.offerForm.wage.range' }), value: 'range' },
        { label: this.$t({ id: 'component.forms.offerForm.wage.from' }), value: 'from' },
        { label: this.$t({ id: 'component.forms.offerForm.wage.up_to' }), value: 'up_to' },
        { label: this.$t({ id: 'component.forms.offerForm.wage.exact' }), value: 'exact' },
      ],
      moneyOptions: [
        { label: this.$t({ id: 'component.forms.offerForm.wage.gross' }), value: 'gross' },
        { label: this.$t({ id: 'component.forms.offerForm.wage.net' }), value: 'net' },
      ],
    }
  },
  computed: {
    common () { return this.$store.state.common },
  },
  created () {
    this.$nextTick(() => {
      if (this.$_.get(this.formObject, 'metadata._job.salaryType')) this.salaryType = this.$_.clone(this.formObject.metadata._job.salaryType)
      if (this.$_.get(this.formObject, 'customAttributes.salaryMin')) this.salaryMin = this.$_.clone(this.formObject.customAttributes.salaryMin)
      if (this.$_.get(this.formObject, 'customAttributes.salaryMax')) this.salaryMax = this.$_.clone(this.formObject.customAttributes.salaryMax)
      if (this.$_.get(this.formObject, 'metadata._job.salaryTax')) this.salaryTax = this.$_.clone(this.formObject.metadata._job.salaryTax)
      if (this.$_.get(this.formObject, 'customAttributes.salaryPeriod')) this.salaryPeriod = this.$_.clone(this.formObject.customAttributes.salaryPeriod)
      if (!this.salaryType) this.salaryType = 'range'
      if (!this.salaryMin) this.salaryMin = null
      if (!this.salaryMax) this.salaryMax = null
      this.stepResult()
    })
  },
  methods: {
    async stepResult () {
      const canGoNext = (this.salaryPeriod && this.salaryTax && (
        (this.salaryType === 'range' && this.salaryMin && this.salaryMax && this.salaryMax > this.salaryMin) ||
        (this.salaryType === 'from' && this.salaryMin) ||
        (this.salaryType === 'up_to' && this.salaryMax) ||
        (this.salaryType === 'exact' && this.salaryMax)
      ))

      const salaryConverter = (this.salaryMin || this.salaryMax) ? await this.$store.dispatch('common/salaryConverter', {
        amount: this.salaryType === 'from' ? this.salaryMin : this.salaryMax,
        unit: this.salaryPeriod,
        tax: this.salaryTax
      }) : null

      this.$emit('stepResult', {
        canGoNext,
        value: {
          customAttributes: {
            salaryMin: parseInt(this.salaryMin),
            salaryMax: parseInt(this.salaryMax),
            salaryPeriod: this.salaryPeriod,
            salaryYearlyGross: parseInt(this.$_.get(salaryConverter, 'yearlyGross', this.$_.get(this.formObject, 'customAttributes, salaryYearlyGross', undefined)))
          },
          metadata: {
            _job: {
              salaryTax: this.salaryTax,
              salaryType: this.salaryType,
            }
          }
        },
      })
    },
  },
}
</script>
