<template>
  <div v-if="!loading" ref="ChipsPicker">
    <div class="row q-col-gutter-md">
      <div v-for="(chip, i) in chipsOptions" :key="i" class="col-4">
        <QChip
          :class="`full-width text-${chipColor(chip)}`"
          :color="chipColor(chip)"
          square
          outline
          :clickable="!readonly"
          no-caps
          style="max-width:calc(100%)"
          @click="toggleChip(chip)"
        >
          <QAvatar :color="chipColor(chip)" text-color="white">
            {{ i + 1 }}
          </QAvatar>
          <div class="ellipsis">
            {{ chip.label ? chip.label : chip }}
            <ActionTooltip :str="chip.label ? chip.label : chip" />
          </div>
        </QChip>
      </div>
    </div>
    <div v-if="step.optional">
      <HCButton
        color="accent"
        class="q-mt-md"
        icon="uil:trash"
        label="prompt.reset_button"
        @click="selectedChips = step.defaultValue ?? defaultUnsetValue; otherChips = defaultUnsetValue; stepResult()"
      />
    </div>
    <template v-if="step.chipsPickerExtended">
      <HCBigButton
        color="secondary"
        class="q-mt-md"
        :label="step.chipsPickerAddLabel"
        @click="showInput = !showInput"
      />
      <QInput
        v-if="showInput"
        v-model="otherInput"
        :readonly="readonly"
        :label="$t({ id: 'component.chips_picker.add' })"
        class="q-mt-md full-width"
        bg-color="white"
        type="text"
        outlined
      >
        <template v-if="otherInput && !readonly" #append>
          <QIcon name="uil:plus" class="cursor-pointer" @click="toggleChip(otherInput)" />
        </template>
      </QInput>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      selectedChips: [],
      otherChips: [],
      showInput: false,
      otherInput: null,
      loading: false, // Used when two following steps use this component
    }
  },
  computed: {
    chipsOptions () {
      return this.$_.compact(this.$_.concat(this.step.options, this.otherChips))
    },
    defaultUnsetValue () {
      return this.step.requestedType === 'Array' ? [] : this.step.requestedType === 'String' ? null : null
    }
  },
  watch: {
    formObject: {
      deep: true,
      handler: function (val, oldVal) { try { this.buildComponent() } catch (e) { this.useLogger(e) } }
    },
    step: {
      deep: true,
      handler: function (val, oldVal) { try { this.buildComponent() } catch (e) { this.useLogger(e) } }
    },
  },
  created () {
    this.buildComponent()
  },
  methods: {
    buildComponent () {
      this.$nextTick(() => {
        // Standard chips
        if (!this.$_.get(this.formObject, this.step.field) && this.$_.get(this.step, 'copyFromObject')) {
          this.selectedChips = this.$_.get(this.step, `copyFromObject.${this.step.field}`, this.defaultUnsetValue)
        } else this.selectedChips = this.$_.get(this.formObject, this.step.field, this.defaultUnsetValue)
        if (this.step.defaultValue && this.$_.isNil(this.selectedChips)) this.selectedChips = this.step.defaultValue

        // Added chips
        if (!this.$_.get(this.formObject, this.$_.get(this.step, 'fields[1]', [])) && this.$_.get(this.step, 'copyFromObject')) {
          this.otherChips = this.$_.get(this.step, `copyFromObject.${this.step.fields[1]}`, this.defaultUnsetValue)
        } else this.otherChips = this.$_.get(this.formObject, this.$_.get(this.step, 'fields', [])[1], this.defaultUnsetValue)
        this.stepResult()
      })
    },
    stepResult () {
      const value = {}
      this.$_.set(value, this.step.field, this.selectedChips)
      if (this.$_.get(this.step, 'fields[1]', false)) this.$_.set(value, this.step.fields[1], this.otherChips)
      this.$emit('stepResult', {
        canGoNext: this.$_.get(this.selectedChips, 'length', 0) > 0 || this.$_.get(this.otherChips, 'length', 0) > 0 || this.$_.get(this.step, 'optional', false),
        value: value,
      })
    },
    toggleChip (chip) {
      if (this.step.requestedType === 'Array') {
        if (this.step.options.includes(chip)) {
          if (!this.selectedChips) this.selectedChips = [chip.value]
          else this.selectedChips = this.$_.xor(this.selectedChips, [chip.value])
        } else this.otherChips = this.$_.xor(this.otherChips, [chip])
      }
      if (this.step.requestedType === 'String') this.selectedChips = chip.value
      this.otherInput = null
      this.stepResult()
    },
    chipColor (chip) {
      if (this.step.requestedType === 'Array' && ((this.step.options.includes(chip) && this.selectedChips && this.selectedChips.includes(chip.value)) || this.otherChips.includes(chip))) return 'secondary'
      else if (this.step.requestedType === 'String' && this.selectedChips === chip.value) return 'secondary'
      return 'grey-4'
    }
  },
}
</script>
