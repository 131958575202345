<template>
  <div
    v-if="data && cptData"
    class="fit"
  >
    <div class="flex items-center justify-between">
      <HCButton
        v-if="title"
        :label="title"
        :icon="icon"
        :to="route ?? undefined"
        force-label-on-xs
      />
      <div
        v-if="caption"
        class="text-body2 text-grey-5"
      >
        {{ caption }}
      </div>
    </div>
    <div
      v-if="cptData.length"
      class="flex"
    >
      <div
        class="svg-item q-mr-md"
        :style="graphStyle"
      >
        <svg width="100%" height="100%" viewBox="0 0 40 40" class="donut">
          <circle class="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="#fff"></circle>
          <circle cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="3.5"></circle>
          <circle
            v-for="item of cptData"
            :key="item.value"
            class="donut-segment"
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="transparent"
            stroke-width="3.5"
            :stroke-dasharray="item.strokeDashArray"
            stroke-dashoffset="25"
            :style="`stroke: ${item.hex};`"
          >
          </circle>
        </svg>
      </div>
      <div class="flex column justify-evenly">
        <div
          v-for="item of cptData"
          :key="item.value"
        >
          <span
            class="text-h6 text-weight-bold q-my-xs q-mr-sm"
            :style="`color: ${item.hex};`"
          >
            {{ item.value }}
          </span>
          <span>
            {{ item.label }}
          </span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="full-width row flex-center text-negative q-gutter-sm"
    >
      <QIcon
        size="2em"
        name="uil:confused"
      />
      <AppContent path="error.no_data" />
    </div>
  </div>
</template>

<script>
// Inspired by : https://codepen.io/janverstr/pen/PvPoaV

export default {
  props: {
    title: {
      type: String,
      default : ''
    },
    icon: {
      type: String,
      default : 'uil:analytics'
    },
    caption: {
      type: String,
      default : ''
    },
    data: {
      type: Array,
      default: () => []
    },
    graphStyle: {
      type: String,
      default: ''
    },
    route: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    cptData () {
      if (!this.data) return []
      const result = []
      const valuesSum = this.data.reduce((acc, item) => acc + item.value, 0)
      let incrementedPercentage = 0

      for (const item of this.data) {
        const percentage = item.value * 100 / valuesSum
        if (percentage === 0) continue
        const itemRotate = incrementedPercentage / 100 * 360
        const style = `content: ""; clip: rect(0 100% 100% 0); -webkit-transform: rotate(${itemRotate}deg);`

        let strokeDashArray = ``
        // If the first
        if (incrementedPercentage === 0) {
          strokeDashArray = `${percentage} ${100 - percentage}`
        } else {
          strokeDashArray = `0 ${incrementedPercentage} ${percentage} ${100 - (percentage + incrementedPercentage)}`
        }

        result.push({
          ...item,
          percentage,
          style,
          percentStart: incrementedPercentage,
          percentEnd: incrementedPercentage + percentage,
          strokeDashArray,
        })
        incrementedPercentage += percentage
      }

      return result.filter(i => i.percentage > 0)
    }
  }
}
</script>

<style>
.svg-item {
  width: 100%;
  font-size: 16px;
  animation: donutfade 1s;
  max-width: 200px;
}

@keyframes donutfade {
  0% {
    opacity: .2;
  }
  100% {
    opacity: 1;
  }
}
</style>
