<template>
  <div
    v-if="company"
    ref="CompanyHeader"
  >
    <section class="relative-position">
      <div :style="`aspect-ratio: 21/6; width:100%; filter: brightness(50%); ${coverUrl}; background-size:cover;`" />
      <div
        class="absolute-center items-center"
        :class="$q.screen.lt.md ? 'flex flex-center items-center' : 'column'"
      >
        <div
          class="gt-xs"
          :class="{'q-mr-md': $q.screen.lt.md}"
        >
          <CompanyLogo
            :size="$q.screen.gt.md ? '130px' : '80px'"
            :company="company"
          />
        </div>
        <div class="q-mt-md q-mb-md text-grey-11">
          <div class="overflow-hidden">
            <div class="overflow-hidden text-center text-weight-bolder text-h4 ellipsis">
              {{ company.name }}
            </div>
          </div>
          <div
            v-if="$_.get(company, 'metadata._public.tagline')"
            class="text-center text-h6 gt-sm"
          >
            {{ company.metadata._public.tagline }}
          </div>
        </div>
        <QBtnGroup
          v-if="!hideMenu"
          flat
          rounded
          class="gt-sm"
        >
          <QBtn
            v-for="menuItem in menuItems"
            :key="menuItem.slug"
            rounded
            :target="embed ? '_blank' : '_self'"
            class="hc-link cursor-pointer"
            :to="!embed ? { name: menuItem.route, params: { slug: company.customAttributes.slug } } : undefined"
            :href="embed ? $web({ name: menuItem.route, params: { slug: company.customAttributes.slug } }) : undefined"
            :class="isActiveTab(menuItem.slug) ? 'bg-purple-3 text-grey-7' : 'bg-background text-grey-5'"
            :label="$t({ id: `navigation.companyHeader.${menuItem.slug}` })"
          />
        </QBtnGroup>
      </div>
      <div
        v-if="$_.get(company, 'metadata._files.unsplashCover', false)"
        class="absolute-bottom-right br-25-left q-pl-xs"
        style="background-color: rgba(255, 255, 255, 0.7);"
      >
        <AppContent
          path="company.unsplash_credit"
          :options="$_.get(company, 'metadata._files.unsplashCover', {})"
          class="float-righttext-right text-caption text-grey-5"
        />
      </div>
    </section>
    <div
      v-if="!hideMenu"
      class="lt-md flex items-center justify-center full-width q-my-sm"
    >
      <QBtnGroup
        flat
        rounded
      >
        <QBtn
          v-for="menuItem in menuItems"
          :key="menuItem.slug"
          rounded
          :target="embed ? '_blank' : '_self'"
          class="hc-link cursor-pointer"
          :to="!embed ? { name: menuItem.route, params: { slug: company.customAttributes.slug } } : undefined"
          :href="embed ? $web({ name: menuItem.route, params: { slug: company.customAttributes.slug } }) : undefined"
          :class="isActiveTab(menuItem.slug) ? 'bg-purple-3 text-grey-7' : 'bg-background text-grey-5'"
          :label="$t({ id: `navigation.companyHeader.${menuItem.slug}` })"
        />
      </QBtnGroup>
    </div>
  </div>
</template>

<script>
import { getNoCacheUrl } from 'hc-core/mixins/aws.js'
import { bgGradient } from 'hc-core/composables/misc.js'
import CompanyLogo from 'hc-core/components/common/company-logo'

export default {
  components: { CompanyLogo },
  props: {
    company: {
      type: Object,
      default: null
    },
    embed: {
      type: Boolean,
      default: false
    },
    hideMenu: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menuItems: [
        { slug: 'page', route: 'companyPage' },
        { slug: 'offers', route: 'companyOffers' },
        { slug: 'locations', route: 'companyLocations' },
      ]
    }
  },
  computed: {
    height () { return this.$q.screen.gt.sm ? 460 : 240 },
    coverUrl () {
      // this.cdnImg
      if (this.$_.get(this.company, 'metadata._files.cover', false)) return `background-image:url(${getNoCacheUrl(this.company.metadata._files.cover)})`
      else return bgGradient(this.$_.get(this.company, 'id', this.$_.get(this.$route, 'params.slug', 'happycab')))
    },
    activeTab () {
      let activeTab = ''
      if (this.$route.name === 'companyPage') activeTab = 'page'
      if (this.$route.name === 'companyOffers' || this.$route.name === 'companyOffer') activeTab = 'offers'
      if (this.$route.name === 'companyLocations') activeTab = 'locations'
      return activeTab
    },
  },
  methods: {
    isActiveTab (tab) { return this.activeTab === tab }
  }
}
</script>
