<template>
  <div ref="Chat" class="fit column q-px-md">
    <div class="col">
      <div v-if="conversation && $_.get(conversation, 'messages.length', 0) > 0" id="scrollAreaId" ref="scrollAreaRef" :key="loading" style="overflow-y: scroll; max-height:100%">
        <QChatMessage
          v-for="(msg, index) of $_.sortBy($_.clone(conversation.messages), ['createdDate', 'asc'])"
          :key="index"
          text-html
          :text="[msg.content]"
          :sent="isApplicant() ? msg.senderId.startsWith('usr_') && msg.senderId === currentUser.id : msg.senderId.startsWith('org_')"
          :stamp="$t({ id: 'time.datetime' }, { fmtd: pickFirstKey(msg, ['metadata.scheduledTimestamp', 'createdDate']) })"
          :bg-color="((isApplicant() && msg.senderId.startsWith('usr_') && msg.senderId === currentUser.id) || (isOrganization() && msg.senderId.startsWith('org_'))) ? 'purple-3' : 'grey-3'"
        >
          <template #avatar>
            <AppAvatar :entity="mtdAvatar(msg)" size="42px" content-class="q-mx-sm" />
          </template>
        </QChatMessage>
        <HCLoading :showing="loading" inner />
      </div>
      <div v-else class="full-width row flex-center text-negative q-gutter-sm">
        <QIcon size="2em" name="uil:confused" />
        <AppContent path="error.no_data" />
      </div>
    </div>
    <div class="col-auto flex items-center no-wrap bg-white" style="z-index: 10;">
      <div class="full-width items-center no-wrap">
        <QSelect v-if="isSubscribed()" v-model="selectedTemplate" :option-label="'name'" :option-value="'id'" :options="$_.concat(defaultTemplates, userTemplates)" class="q-my-sm" :label="$t({ id: 'component.dialogs.application_mail_dialog.templates' })" dense outlined @update:model-value="updateTemplate">
          <template #option="scope">
            <QItem v-bind="scope.itemProps" v-on="scope.itemEvents">
              <QItemSection>
                <div class="text-weight-medium">
                  {{ scope.opt.name ?? scope.opt.data.name ?? 'Label non défini' }}
                </div>
              </QItemSection>
              <QItemSection avatar>
                <AppContent v-if="scope.opt.label" :class="scope.opt.label.includes('meeting') ? 'bg-positive text-white' : scope.opt.label.includes('refused') ? 'bg-negative text-white' : 'bg-dark text-white'" class="text-caption" square tag="QChip" :path="`component.templates.types_short.${scope.opt.label}`" />
              </QItemSection>
            </QItem>
          </template>
        </QSelect>
        <QEditor
          ref="editorRef"
          v-model="newMessage"
          min-height="5rem"
          class="full-width"
          :definitions="computedDefinitions"
          :toolbar="[
            ['bold', 'italic', 'strike', 'underline'],
            ['unordered', 'ordered'],
            ['charsCounter'],
          ]"
          @paste="onPaste"
        />
      </div>
      <div class="q-ml-sm flex column q-gutter-y-sm">
        <QBtn :loading="loading" :disabled="loading || newMessage.length === 0" icon="uil:message" round color="primary" size="14px" @click="sendMessage({ isScheduled: false })">
          <ActionTooltip path="prompt.send_button" />
        </QBtn>
        <QBtn :loading="loading" :disabled="loading || newMessage.length === 0" icon="uil:clock" round color="secondary" size="14px" @click="scheduleDialog = true">
          <ActionTooltip path="prompt.schedule_send_button" />
        </QBtn>
        <QBtn v-if="$_.get(conversation, 'scheduledToSend.length', false)" icon="uil:mailbox" round color="secondary" size="14px" @click="scheduledDialog = true">
          <ActionTooltip path="prompt.scheduled_show_button" />
        </QBtn>
      </div>
    </div>
    <QDialog v-model="scheduleDialog" :maximized="$q.screen.lt.sm" @hide="scheduleDialog = false">
      <AccountCard path="component.dialogs.application_mail_dialog.send_message" icon="uil:fast-mail" style="min-width:80%;">
        <template #header-right>
          <div class="flex items-center gt-xs">
            <HCButton v-close-popup is-close />
          </div>
        </template>
        <QCardSection>
          <div class="full-width flex justify-around">
            <QDate v-model="scheduledTimestamp" mask="YYYY-MM-DD HH:mm" :options="optionsDate" />
            <QTime v-model="scheduledTimestamp" mask="YYYY-MM-DD HH:mm" format24h :hour-options="[8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]" />
          </div>
          <div class="full-width flex justify-between q-mt-md">
            <HCBigButton color="negative" label="prompt.cancel_button" @click="scheduleDialog = false" />
            <HCBigButton color="positive" label="prompt.schedule_send_button" :disable="!scheduledTimestamp || new Date(scheduledTimestamp) <= new Date()" @click="sendMessage({ isScheduled: true }); scheduleDialog = false" />
          </div>
        </QCardSection>
      </AccountCard>
    </QDialog>
    <QDialog v-model="scheduledDialog" :maximized="$q.screen.lt.sm" @hide="scheduledDialog = false">
      <AccountCard path="prompt.scheduled_show_button" icon="uil:mailbox" style="min-width:80%;">
        <template #header-right>
          <div class="flex items-center gt-xs">
            <HCButton v-close-popup is-close />
          </div>
        </template>
        <QCardSection>
          <QList dark bordered separator>
            <QItem v-for="msg of $_.sortBy($_.clone(conversation.scheduledToSend), ['metadata.scheduledTimestamp', 'desc'])" :key="msg.id" v-ripple clickable class="bg-secondary-light">
              <QItemSection>
                <QItemLabel class="text-grey-7">
                  <!-- eslint-disable vue/no-v-html -->
                  <div v-html="msg.content" />
                  <!-- eslint-enable vue/no-v-html -->
                </QItemLabel>
                <QItemLabel class="text-grey-4" caption>
                  <AppContent path="time.datetime" :options="{ fmtd: $_.get(msg, 'metadata.scheduledTimestamp', $_.get(msg, 'createdDate')) }" />
                </QItemLabel>
              </QItemSection>
              <QItemSection top side>
                <HCBigButton color="negative" label="prompt.cancel_button" @click="deleteMessage(msg); scheduledDialog = false" />
              </QItemSection>
            </QItem>
          </QList>
        </QCardSection>
      </AccountCard>
    </QDialog>
  </div>
</template>

<script>
import { stripTags } from 'hc-core/composables/misc.js'
import TemplatesMixins from 'hc-core/mixins/templates'
import { manipulateTime } from 'hc-core/composables/time.js'

export default {
  mixins: [TemplatesMixins],
  props: {
    conversation: {
      type: Object,
      required: true,
    },
    application: {
      type: Object,
      required: false,
      default: () => {}
    },
    applicant: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  emits: ['refresh'],
  data () {
    return {
      loading: false,
      newMessage: '',
      scheduledTimestamp: null,
      scheduleDialog: false,
      scheduledDialog: false,
      editorOptions: {
        hideModeSwitch: true,
        minHeight: '120px',
        maxHeight: '400px',
        toolbarItems: ['bold', 'italic', 'strike', 'divider', 'ul', 'ol']
      },
      selectedTemplate: null,
      userTemplates: [],
      defaultTemplates: [],
    }
  },
  computed: {
    talkAssets () { return this.$store.getters['inbox/talkAssets'] },
    usersById () { return this.$store.getters['inbox/inbox'].usersById },
    usersBank () {
      return this.$_.union(
        [this.currentNaturalUser, this.currentUser],
        this.$uElements('organizations')
      )
    },
    computedDefinitions () {
      return {
        charsCounter: {
          tip: this.$t({ id: 'component.tools.chat.chars' }),
          icon: 'uil:text-size',
          label: `${stripTags(this.newMessage).length} / 3000`,
        },
      }
    }
  },
  watch: {
    conversation: {
      handler: async function (conversation, oldConversation) {
        if (conversation) this.scrollToBottom()
      }
    },
  },
  async mounted () {
    try {
      if (process.env.CLIENT) {
        this.scrollToBottom()
        // Set scheduledTimestamp to tommorow 8:00AM
        const d = new Date()
        d.setDate(new Date().getDate() + 1)
        d.setHours(8)
        d.setMinutes(0)
        const date = d.toISOString().split('T')[0]
        const time = d.toTimeString().split(' ')[0]
        this.scheduledTimestamp = `${date} ${time}`
        await this.$store.dispatch('inbox/fetchInbox', {
          id: this.currentNaturalUser.id,
          withInterlocutors: true,
          withCompanyAssets: this.isApplicant() ?? undefined,
          withMessages: true,
          interlocutorId: this.conversation.interlocutorId
        })
      }
      if (this.isSubscribed()) {
        this.defaultTemplates = await this.$store.dispatch('inbox/getTemplates', { authorId: 'platform' })
        await this.$store.dispatch('auth/getUserOrganizations', { fields: ['templates'] })
        this.userTemplates = this.$uElements('templates')
      }
    } catch (e) { this.useLogger(e) }
  },
  methods: {
    mtdAvatar (msg) {
      if (this.isApplicant()) {
        return this.$_.get(this.conversation, 'companyAsset.ownerId', null) === msg.senderId ? this.conversation.companyAsset : this.usersBank.find(u => u.id === msg.senderId)
      } else {
        // As client, come from my asset
        if (this.usersBank.find(u => u.id === msg.senderId)) return this.userCompany
        else return this.conversation.interlocutor
      }
    },
    optionsDate (date) {
      return new Date(date) >= manipulateTime({ unit: 'days', nb: -1 }) && new Date(date) <= manipulateTime({ unit: 'years', nb: 1 }) && ![0, 6].includes(new Date(date).getDay())
    },
    updateTemplate () {
      this.newMessage = this.buildTemplate(this.selectedTemplate.content ?? this.selectedTemplate.data.content, {
        applicant: this.usersById[this.conversation.interlocutorId] ?? this.applicant,
        company: this.userCompany,
        offer: this.$_.get(this.application, 'assetSnapshot', undefined),
        locations: this.userCompany.locations
      })
    },
    async sendMessage ({ isScheduled = false }) {
      try {
        if (this.newMessage === '' || !this.newMessage.length) return
        this.loading = true

        // Link transactions in attachments
        let attachments = this.$_.get(this.conversation, 'messages[0].attachments', [])
        const isV2Conv = ![this.talkAssets.choTopicId, this.talkAssets.userTopicId, this.talkAssets.administrativeTopicId].includes(this.conversation.topicId)
        if (isV2Conv) attachments.push({ type: 'transaction', id: this.conversation.topicId })
        attachments = this.$_.uniqBy(attachments, ['id'])

        await this.$store.dispatch('inbox/sendMessage', {
          attachments,
          topicId: this.conversation.topicId,
          content: this.newMessage,
          senderId: this.currentUser.id,
          conversationId: this.$_.get(this.conversation, 'convId', undefined),
          receiverId: this.conversation.interlocutorId,
          metadata: isScheduled ? { scheduledToSend: true, scheduledTimestamp: new Date(this.scheduledTimestamp).toISOString() } : undefined
        })
        this.newMessage = ''
        this.$emit('refresh')
        this.notifySuccess('notification.message_sent_success')
      } catch (e) {
        this.useLogger(e)
      } finally {
        this.loading = false
      }
    },
    async deleteMessage (message) {
      try {
        if (!message) throw new Error()
        this.loading = true
        await this.$store.dispatch('inbox/deleteMessage', { message })
        this.$emit('refresh')
        this.notifySuccess('notification.message_delete_success')
      } catch (e) {
        this.useLogger(e)
      } finally {
        this.loading = false
      }
    },
    onPaste (evt) {
      // Let inputs do their thing, so we don't break pasting of links.
      if (evt.target.nodeName === 'INPUT') return
      let text, onPasteStripFormattingIEPaste
      evt.preventDefault()
      evt.stopPropagation()
      if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
        text = evt.originalEvent.clipboardData.getData('text/plain')
        this.$refs.editorRef.runCmd('insertText', text)
      } else if (evt.clipboardData && evt.clipboardData.getData) {
        text = evt.clipboardData.getData('text/plain')
        this.$refs.editorRef.runCmd('insertText', text)
      } else if (window.clipboardData && window.clipboardData.getData) {
        if (!onPasteStripFormattingIEPaste) {
          onPasteStripFormattingIEPaste = true
          this.$refs.editorRef.runCmd('ms-pasteTextOnly', text)
        }
        onPasteStripFormattingIEPaste = false
      }
    },
    scrollToBottom () {
      this.$nextTick(() => {
        const objDiv = document.getElementById('scrollAreaId')
        if (objDiv) {
          // Both cause not working each time
          objDiv.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
          objDiv.scrollTop = objDiv.scrollHeight
        }
      })
    },
  },
}
</script>
