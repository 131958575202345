<template>
  <QCard
    v-if="plan"
    ref="PlanCard"
    :class="[!minimal && plan.fields.recommended && !hideRecommend && !isCurrent ? 'bd-2 bd-warning relative-position' : '', minimal ? '' : 'full-height', isCurrent ? 'bd-2 bd-positive' : '', 'br-8']"
    flat
  >
    <div
      :class="{'full-height': !minimal}"
      class="flex column justify-between"
    >
      <div>
        <div
          v-if="!minimal && plan.fields.recommended && !hideRecommend && !isCurrent"
          class="flex flex-center"
        >
          <QBadge
            class="no-border-radius q-pa-sm text-weight-bold"
            style="margin-top:-1rem"
            color="warning"
            text-color="dark"
            :label="$t({ id: 'component.cards.plan_card.recommended' })"
          />
        </div>
        <div
          v-if="isCurrent"
          class="flex flex-center"
        >
          <QBadge
            class="no-border-radius q-pa-sm text-weight-bold text-white"
            style="margin-top:-1rem"
            color="positive"
            text-color="dark"
            :label="$t({ id: 'component.cards.plan_card.current' })"
          />
        </div>
        <QCardSection>
          <div class="flex items-center q-gutter-md no-wrap">
            <img
              v-if="$_.get(plan, 'fields.product.images[0]', false)"
              :src="plan.fields.product.images[0]"
              loading="lazy"
              alt="i"
              style="width:90px"
            >
            <div>
              <div :class="`text-weight-bold text-h6 text-${$_.get(plans, `${plan.name}.color`, 'primary')}`">
                {{ plan.fields.title }}
              </div>
              <div
                v-if="!price && $_.get(plan, 'fields.product.prices') && showPrice"
                class="text-weight-bold text-h6"
              >
                <div v-if="$_.find(plan.fields.product.prices, { id: selectedPrice }) && !plan.fields.textPrice">
                  <AppContent
                    path="number.price"
                    :options="{ price: $_.find(plan.fields.product.prices, { id: selectedPrice }).unit_amount / 100 }"
                  />
                </div>
                <div v-if="plan.fields.textPrice">
                  {{ plan.fields.textPrice }}
                </div>
              </div>
              <div
                v-if="computedPrice && showPrice"
                class="text-weight-bold text-h6"
              >
                <AppContent
                  path="number.price"
                  :options="{ price: computedPrice.ht }"
                />
              </div>
              <div
                v-else-if="price && showPrice"
                class="text-weight-bold text-h6"
              >
                <AppContent
                  path="number.price"
                  :options="{ price: price.unit_amount / 100 }"
                />
              </div>
              <div
                v-if="$_.get(plan, 'fields.product.metadata.slug', false) && showPrice"
                class="text-subtitle2 text-weight-regular text-grey-5"
              >
                {{ plan.fields.cycle }}
              </div>
            </div>
          </div>
          <div
            v-if="price && !minimal && plan.fields.showPrices && !isCurrent"
            class="flex no-wrap q-mt-md"
          >
            <QBtn
              v-for="productPrice of plan.fields.product.prices"
              :key="productPrice.id"
              :color="selectedPrice && $_.find(plan.fields.product.prices, { id: selectedPrice }).id === productPrice.id ? 'positive' : 'grey-3'"
              :text-color="selectedPrice && $_.find(plan.fields.product.prices, { id: selectedPrice }).id === productPrice.id ? 'white' : 'grey-9'"
              size="md"
              style="width:50%;"
              class="no-border-radius"
              dense
              no-caps
              unelevated
              @click="selectedPrice = productPrice.id"
            >
              <div class="q-py-sm">
                {{ productPrice.nickname }}
              </div>
            </QBtn>
          </div>
          <!-- <div v-else-if="!minimal && !isCurrent" class="flex items-center no-wrap q-mt-lg text-grey-5" style="height:46px;">
            <div v-html="plan.fields.placeholder" />
          </div> -->
        </QCardSection>
        <QCardSection
          v-if="!minimal"
          class="flex-center"
        >
          <HCBigButton
            v-if="plan.name !== 'dragon'"
            class="full-width"
            :color="planColor"
            :href="computedHref"
            label="components.hc_button.go_subscribe"
          />
          <HCBigButton
            v-else
            class="full-width"
            :color="planColor"
            :href="computedHrefDragon"
            label="Contactez-nous"
          />
        </QCardSection>
        <!-- <QCardSection>
          <QList v-if="small">
            <QItem v-if="plan.name !== 'etincelle'" :key="'allPrevious'" :class="{'q-px-none': $q.screen.lt.md}" class="q-pl-none">
              <QItemSection side class="flex flex-center">
                <QIcon size="1.3rem" color="positive" name="uil:check" />
              </QItemSection>
              <QItemSection class="text-dark text-body2">
                <AppContent path="component.cards.plan_card.all_features" :options="{ plan: plan.name }" />
              </QItemSection>
            </QItem>
            <QItem v-for="feature in uniqueFeatures" :key="feature.id" :class="{'q-px-none': $q.screen.lt.md}" style="min-height: 40px;" class="q-py-xs q-pl-none">
              <QItemSection side class="flex flex-center">
                <QIcon size="1.3rem" :color="hasFeature(feature).icon.color" :name="hasFeature(feature).icon.name" />
              </QItemSection>
              <QItemSection :class="hasFeature(feature).textColor">
                <div class="text-body2">
                  {{ feature.fields.title }}
                </div>
                <div v-if="$_.get(feature, `fields.disclaimer.${plan.name}`, false)" class="text-caption text-grey">
                  {{ feature.fields.disclaimer[plan.name] }}
                </div>
              </QItemSection>
            </QItem>
          </QList>
          <QList v-else>
            <template v-for="feature in features" :key="feature.id">
              <QItem v-if="!minimal || plan.fields.features.includes(feature.name)" :class="{'q-px-none': $q.screen.lt.md}" style="min-height: 40px;" class="q-py-xs q-pl-none">
                <QItemSection side class="flex flex-center">
                  <QIcon size="1.3rem" :color="hasFeature(feature).icon.color" :name="hasFeature(feature).icon.name" />
                </QItemSection>
                <QItemSection :class="hasFeature(feature).textColor" class="text-body2">
                  <div class="text-body2">
                    {{ feature.fields.title }}
                  </div>
                  <div v-if="$_.get(feature, `fields.disclaimer.${plan.name}`, false)" class="text-caption text-grey">
                    {{ feature.fields.disclaimer[plan.name] }}
                  </div>
                </QItemSection>
              </QItem>
            </template>
          </QList>
        </QCardSection> -->
        <QCardSection>
          <QList>
            <QItem
              v-for="(f, i) of computedFeaturesList"
              :key="i"
              class="q-pl-none"
            >
              <QItemSection
                side
                class="flex flex-center"
              >
                <QIcon
                  size="1.3rem"
                  :color="f.included ? 'positive' : 'grey-5'"
                  :name="`uil:${f.included ? 'check' : 'times'}`"
                />
              </QItemSection>
              <QItemSection :class="`text-body2 ${f.included ? 'text-dark' : 'text-grey-5'}`">
                <QItemLabel>
                  <AppContent :path="`shop.features.${$_.get(f, 'title', f)}`" />
                </QItemLabel>
                <QItemLabel
                  v-if="$_.get(f, 'subtitle', false)"
                  caption
                >
                  <AppContent :path="`shop.features.${$_.get(f, 'subtitle', f)}`" />
                </QItemLabel>
              </QItemSection>
            </QItem>
          </QList>
        </QCardSection>
      </div>
      <div
        v-if="plan && plan.fields.disclaimer"
        class="q-pb-md text-center text-weight-bold text-subtitle1 q-px-md"
      >
        {{ plan.fields.disclaimer }}
      </div>
    </div>
  </QCard>
</template>

<script>
export default {
  props: {
    hideRecommend: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    minimal: {
      type: Boolean,
      default: false
    },
    plan: {
      type: Object,
      default: null
    },
    price: {
      type: Object,
      default: null
    },
    computedPrice: {
      type: Number,
      default: null
    },
    features: {
      type: Array,
      default: () => []
    },
    showPrice: {
      type: Boolean,
      default: true
    },
    uniqueFeatures: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedPrice: null,
      plans: {
        starter: {
          color: 'blue-4',
          cta_label: 'Je m\'abonne !',
          features: [
            'company_page',
            { title: 'ats', subtitle: 'ats_auto' },
            { title: 'offers_limited', subtitle: 'applications_unlimited' },
            { title: 'multicasting', subtitle: 'multicasting_sponsorship' },
            { title: 'applications_spontaneous', subtitle: 'applications_spontaneous_limited_10' },
            'commit_no',
          ],
        },
        etincelle: {
          color: 'purple-4',
          cta_label: 'Je créée ma page',
          features: [
            'company_page',
            // { title: 'applications_spontaneous', subtitle: 'applications_spontaneous_limited_3' },
            'commit_no',
          ],
        },
        phenix: {
          color: 'gold-4',
          cta_label: 'Je m\'abonne !',
          features: [
            'company_page',
            { title: 'ats', subtitle: 'ats_auto' },
            { title: 'offers_unlimited', subtitle: 'applications_unlimited' },
            { title: 'applications_spontaneous', subtitle: 'applications_spontaneous_unlimited' },
            'multicasting',
            'testimonials',
            'commit_year',
          ],
        },
        dragon: {
          color: 'red-4',
          cta_label: 'Nous contacter',
          features: [
            'company_page',
            { title: 'ats', subtitle: 'ats_auto' },
            { title: 'offers_unlimited', subtitle: 'applications_unlimited' },
            { title: 'applications_spontaneous', subtitle: 'applications_spontaneous_unlimited' },
            'multicasting',
            'testimonials',
            'locations',
            'vdc_spotlight',
            'content_creation',
            // 'video',
            // 'interviews',
            'cho',
            'commit_year',
          ],
        },
      },
      allFeatures: [
        'company_page',
        'ats',
        ['offers_unlimited', 'offers_limited'],
        'applications_spontaneous',
        'multicasting',
        'testimonials',
        'locations',
        'vdc_spotlight',
        'content_creation',
        // 'video',
        // 'interviews',
        'cho',
        ['commit_no', 'commit_year']
      ],
    }
  },
  computed: {
    computedFeaturesList () {
      // const itemFeatures = this.$_.get(this.plan, 'fields.features', []) // From entry
      const itemFeatures = this.$_.get(this.plans, `${[this.plan.name]}.features`, []) // Raw dev
      return this.allFeatures.map(f => {
        const hasAsString = itemFeatures.includes(f)
        const hasAsObject = itemFeatures.find(pf => pf.title === f || (Array.isArray(f) && f.includes(pf.title)))
        if (Array.isArray(f)) {
          const includedArrayFeature = f.find(item => itemFeatures.some(pf => (pf.title || pf) === item))
          return { title: includedArrayFeature || f[0], included: !!includedArrayFeature, ...(hasAsObject || {}) }
        } else {
          return { title: f, included: hasAsString || !!hasAsObject, ...(hasAsObject || {}) }
        }
      }).filter(({ included }) => this.small ? included : true)
    },
    currentUserId () { return this.$_.get(this, '$store.getters[`auth/currentUser`].id', false) },
    planColor () {
      return this.$_.get(this.plans, `${this.plan.name}.color`, 'primary')
    },
    isCurrent () {
      if (process.env.HC_PLATFORM === 'WEB') return false
      return (this.plan.name === 'phenix' && (this.isPhenix() || this.isSubscribed() === 'phenix')) ||
        (this.plan.name === 'dragon' && (this.isDragon() || this.isSubscribed() === 'dragon')) ||
        (this.plan.name === 'etincelle' && ((this.isClient() && !this.isSubscribed()) || (this.isOrganization() && !this.isSubscribed())))
    },
    computedHref () {
      if (process.env.HC_PLATFORM === 'WEB') return this.currentUserId ? this.$app() : this.$app({ name: 'signupCompany' })
      else if (process.env.HC_PLATFORM === 'APP') return this.$app({ name: 'checkout', params: { type: this.plan.name } })
      else return this.$app()
    },
    computedHrefDragon () {
      if (process.env.HC_PLATFORM === 'WEB') return this.$router.resolve({ name: 'contact' }).href
      else if (process.env.HC_PLATFORM === 'APP') return this.$web({ name: 'contact' })
      else return this.$web({ name: 'contact' })
    }
  },
  mounted () {
    this.selectedPrice = this.plan.fields.defaultPrice
  },
  methods: {
    hasFeature (feature) {
      if (this.plan.fields.features.includes(feature.name)) return { textColor: 'text-dark', icon: { color: 'positive', name: 'uil:check' } }
      return { textColor: 'text-grey-5', icon: { color: 'grey-5', name: 'uil:times' } }
    }
  },
}
</script>
