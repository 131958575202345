<template>
  <div ref="MultipleItems">
    <div v-if="!loading" class="row full-width q-col-gutter-xs">
      <template v-for="(itemData, i) of step.multipleItemsData">
        <component
          :is="itemData.component"
          v-if="$_.get(itemData, 'if', true)"
          :key="i"
          :step="itemData"
          :field="itemData"
          :form-object="formObject"
          :entity="formObject"
          :readonly="readonly"
          light
          :class="$_.get(itemData, 'class', 'col-12')"
          :style="$_.get(itemData, 'style', undefined)"
          :mask="$_.get(itemData, 'mask', undefined)"
          :rules="$_.get(itemData, 'rules', undefined)"

          :value="$_.get(result, itemData.field, null)"
          :placeholder="$_.get(itemData, 'placeholder', undefined)"

          @stepResult="checkComponent($event, itemData, i)"
        />
      </template>
    </div>
    <HCLoading v-else />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
const ISOInput = defineAsyncComponent(() => import('hc-core/components/forms/iso-input'))
const TextInput = defineAsyncComponent(() => import('hc-core/components/forms/text-input'))
const ToolsPicker = defineAsyncComponent(() => import('hc-core/components/forms/tools-picker'))
const SelectInput = defineAsyncComponent(() => import('hc-core/components/forms/select-input'))
const SliderInput = defineAsyncComponent(() => import('hc-core/components/forms/slider-input'))
const OptionsInput = defineAsyncComponent(() => import('hc-core/components/forms/options-input'))
const PasswordInput = defineAsyncComponent(() => import('hc-core/components/forms/password-input'))
const CheckboxInput = defineAsyncComponent(() => import('hc-core/components/forms/checkbox-input'))
const HeadcountInput = defineAsyncComponent(() => import('hc-core/components/forms/headcount-input'))
const AdvantagesInput = defineAsyncComponent(() => import('hc-core/components/forms/advantages-input'))
const ItemsArrayInput = defineAsyncComponent(() => import('hc-core/components/forms/items-array-input'))
const PlacesAutocomplete = defineAsyncComponent(() => import('hc-core/components/inputs/places-autocomplete'))
const ImageCropperUploader = defineAsyncComponent(() => import('hc-core/components/files/image-cropper-uploader'))

export default {
  components: {
    ISOInput,
    TextInput,
    ToolsPicker,
    SelectInput,
    SliderInput,
    OptionsInput,
    PasswordInput,
    CheckboxInput,
    HeadcountInput,
    AdvantagesInput,
    ItemsArrayInput,
    PlacesAutocomplete,
    ImageCropperUploader,
  },
  mixins: [],
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      result: {},
      loading: false,
      itemsCanGoNext: [],
    }
  },
  watch: {
    step: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        this.loading = true
        this.$nextTick(() => { this.loading = false })
      }
    }
  },
  created () {
    this.$nextTick(() => {
      for (const f of this.step.multipleItemsData) {
        this.$_.set(this.result, f.field, this.$_.get(this.formObject, f.field, undefined))
      }
      setTimeout(() => { this.stepResult() }, 1000)
    })
  },
  methods: {
    checkComponent (evt, itemData, i) {
      this.$_.set(this.itemsCanGoNext, `[${i}]`, this.$_.get(evt, 'canGoNext', false))
      if (this.$_.get(evt, 'canGoNext', false)) {
        this.$_.set(this.result, itemData.field, this.$_.get(evt.value, itemData.field, undefined))
        this.stepResult()
      }
    },
    textDisplay (item, key = 'isTitle') {
      const r = this.$_.get(item, this.$_.get(this.step.arrayItemModel.find(k => this.$_.get(k, key, false)), 'field', false), false)
      if (this.$_.get(this.step, `transform.${key}`, false)) return this.step.transform[key](r)
      else return r
    },
    stepResult () {
      this.$emit('stepResult', {
        canGoNext: (Object.keys(this.result).length > 0 && this.itemsCanGoNext.reduce((acc, cgn) => acc && cgn, true)) || this.$_.get(this.step, 'optional', false),
        value: this.result,
      })
    },
  }
}
</script>
