<template>
  <div ref="OfferLocation">
    <QSelect
      v-if="ready"
      v-model="selectedLocations[0]"
      :label="$t({ id: 'component.forms.offerForm.location.select_locations' })"
      dropdown-icon="uil:angle-down"
      bg-color="white"
      style="max-width:85vw;"
      :options="locations"
      :readonly="readonly"
      outlined
      :display-value="getLocationLabel($_.get(selectedLocations, '[0]', null))"
      @update:model-value="stepResult"
    >
      <template #option="scope">
        <QItem v-bind="scope.itemProps" :class="{'bg-green-3 text-weight-medium': selectedLocations.includes(scope.opt)}">
          <QItemSection>
            <QItemLabel>
              {{ getLocationLabel(scope.opt) }}
            </QItemLabel>
          </QItemSection>
        </QItem>
      </template>
    </QSelect>
    <div v-if="isOrganization" class="q-gutter-sm">
      <QCheckbox v-model="seeAllLocations" color="primary" label="Voir toutes les implantations" />
    </div>
  </div>
</template>

<script>
import { getLocationLabel } from 'hc-core/composables/misc.js'

export default {
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      ready: false,
      seeAllLocations: false,
      selectedLocations: [],
    }
  },
  computed: {
    locations () {
      if (this.isOrganization) {
        if (!this.seeAllLocations) return this.userCompany.locations
        const allLocations = this.$_.flatten(this.$uElements('companies').map(function (company) { return company.locations }))
        if (this.location) {
          allLocations.slice(allLocations.indexOf(this.location), 1)
          allLocations.unshift(this.location)
        }
        return this.$_.uniqBy(allLocations, 'id')
      } else {
        return this.userCompany.locations
      }
    },
  },
  created () {
    this.$nextTick(() => {
      if (this.formObject && this.formObject.locations) this.selectedLocations = this.$_.compact([this.$_.get(this.formObject, 'locations[0]', null)])
      this.ready = true
      this.stepResult()
    })
  },
  methods: {
    getLocationLabel,
    stepResult () {
      const value = {}
      this.$_.set(value, 'locations', this.$_.compact(this.selectedLocations))
      this.$emit('stepResult', {
        canGoNext: this.selectedLocations.length || (this.step && this.step.optional),
        value: value,
      })
    },
  },
}
</script>
