<template>
  <AccountCard ref="AccountChoCard" :loading="!ready" icon="uil:comments" path="component.company.account.cho.title">
    <QCardSection>
      <div class="flex justify-between q-mb-md">
        <AppContent class="text-subtitle2 text-grey" path="component.company.account.cho.subtitle" :options="{ choName: user.displayName }" />
      </div>
      <QSeparator />
      <!-- MD+ -->
      <div class="flex items-center q-mt-sm">
        <AppAvatar :entity="user" size="100px" class="gt-sm q-mr-md" />
        <div class="flex column items-start">
          <div class="gt-sm text-body1-bold q-mr-md">
            {{ user.displayName }}
          </div>
          <AppContent class="gt-sm" path="component.company.account.cho.job" />
          <div class="lt-md flex items-center justify-start q-my-sm">
            <div class="text-body1-bold q-mr-md">
              {{ user.displayName }}
            </div>
            <AppContent path="component.company.account.cho.job" />
          </div>
          <HCButton v-if="$_.get(user, 'email', false)" force-label-on-xs :label="user.email" :href="`mailto:${user.email}`" icon="uil:envelope-alt" />
          <HCButton v-if="$_.get(user, 'metadata._private.phone', false)" force-label-on-xs :label="user.metadata._private.phone" :href="`tel:${user.metadata._private.phone}`" icon="uil:phone" />
          <!-- <HCBigButton :label="`Discuter avec ${user.displayName}`" class="q-px-sm" @click="choMeetingDialog = true" /> -->
        </div>
      </div>
    </QCardSection>
    <QCardSection v-if="choMeetingDialog">
      <div class="q-gutter-y-md">
        <div class="row">
          <AppContent class="col" path="component.company.account.cho.cho_meeting_dialog" :options="{ choName: user.displayName }" />
          <HCButton class="col-1" is-close @hide="choMeetingDialog = false" />
        </div>
        <QInput v-model="text" color="grey-3" bg-color="white" class="full-width" type="textarea" outlined :placeholder="$t({ id: 'component.company.account.cho.placeholder' })" dense />
        <div class="justify-between q-mt-md flex">
          <HCBigButton color="positive" label="prompt.confirm_button" @click="choMeetingEvent" />
        </div>
      </div>
    </QCardSection>
  </AccountCard>
</template>

<script>
import { getCurrentUserId } from 'hc-core/composables/auth.js'

export default {
  emits: ['eventSent'],
  data () {
    return {
      user: {},
      text: '',
      ready: false,
      choMeetingDialog: false,
    }
  },
  async created () {
    this.user = await this.$store.dispatch('user/read', { id: this.$store.getters['user/choUserId'] })
    this.$nextTick(() => {
      this.ready = true
    })
  },
  methods: {
    async choMeetingEvent () {
      try {
        await this.$store.dispatch('common/sendCustomEvent', {
          type: 'cho_meeting_requested',
          objectId: getCurrentUserId(),
          metadata: {
            topicId: this.$store.getters['inbox/talkAssets'].choTopicId,
            subject: this.text,
            origin: 'choCard',
            isSubscribed: this.isSubscribed(),
          }
        })
        this.notifySuccess('component.company.account.cho.success')
        this.text = ''
      } catch (e) {
        this.useLogger(e)
      } finally {
        this.$emit('eventSent')
        this.choMeetingDialog = false
      }
    }
  }

}
</script>
