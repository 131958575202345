<template>
  <div v-if="actions" class="flex items-center justify-end">
    <template v-if="forceExpand || (expanded && $q.screen.gt.xs)">
      <HCButton
        v-for="(action, i) in cptActions"
        :key="i"
        :icon="action.icon"
        :class="action.cptClass"
        :color="action.color"
        :tooltip="action.label ? action.label : undefined"
        :to="action.to"
        :href="action.href"
        :target="action.target"
        @click.capture.stop="action.fn ? action.fn(element) : null"
      />
    </template>
    <QBtn v-else dense size="sm" label="Voir plus" flat no-caps color="dark" style="text-decoration: underline;" @click.capture.stop="menuModel = !menuModel">
      <QMenu v-model="menuModel" :targe="false" content-class="no-box-shadow" no-parent-event flat>
        <QList bordered flat style="min-width: 100px">
          <QItem
            v-for="(action, i) in cptActions"
            :key="i"
            v-close-popup
            clickable
            :class="action.cptClass"
            :to="action.to"
            :href="action.href"
            :target="action.target"
            @click.stop.prevent="action.fn ? action.fn(element) : null"
          >
            <QItemSection class="flex flex-center" avatar>
              <QIcon :name="action.icon" />
            </QItemSection>
            <QItemSection>
              {{ computedLabel(action.label) }}
            </QItemSection>
          </QItem>
        </QList>
      </QMenu>
    </QBtn>
  </div>
</template>

<script>
export default {
  props: {
    actions: {
      type: Array,
      required: true
    },
    element: {
      type: Object,
      required: false,
      default: () => null
    },
    expanded: {
      type: Boolean,
      default: false
    },
    forceExpand: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menuModel: false
    }
  },
  computed: {
    cptActions () {
      return this.$_.compact(this.actions.map((action) => {
        action.icon = action.icon ?? 'uil:info-circle'
        action.label = this.computedLabel(action.label)
        action.to = this.$_.get(action, 'to', undefined)
        action.href = this.$_.get(action, 'href', undefined)
        action.target = action.href ? '_blank' : '_self'
        action.color = this.$_.get(action, 'color', 'primary')
        // Named property different than 'class' so no recursive updates loop
        action.cptClass = `${action.class ?? ''} text-${this.$_.get(action, 'color', 'black')}`
        action.fn = this.$_.get(action, 'fn', undefined)
        return this.$_.get(action, 'if', true) ? action : null
      }))
    }
  },
  methods: {
    computedLabel (label) {
      return label ? this.translationExists(label) ? this.$t({ id: label }) : label : false
    },
  }
}
</script>
