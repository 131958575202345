<template>
  <div class="q-mb-md">
    <QCheckbox
      v-model="headcountPrecision"
      :label="$t({ id: 'company.headcountPrecision' })"
      :disable="readonly"
      @update:model-value="stepResult"
    />
    <QInput
      v-if="$_.get(this, 'headcountPrecision', false)"
      v-model.number="headcount"
      :label="$t({ id: 'company.headcount' })"
      type="number"
      class="br-8"
      color="grey-5"
      bg-color="white"
      outlined
      :readonly="readonly"
      @update:model-value="stepResult"
    />
    <QSelect
      v-if="!$_.get(this, 'headcountPrecision', false)"
      v-model="headcountRange"
      :label="$t({ id: 'company.headcountRange' })"
      :options="getCustomAttributeValues('headcountRange')"
      bg-color="white"
      outlined
      emit-value
      map-options
      :readonly="readonly"
      @update:model-value="stepResult"
    />
  </div>
</template>

<script>
export default {
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      headcount: null,
      headcountRange: null,
      headcountPrecision: null,
    }
  },
  created () {
    this.$nextTick(() => {
      if (this.$_.get(this.formObject, 'metadata._team.headcount')) this.headcount = this.$_.clone(this.formObject.metadata._team.headcount)
      if (this.$_.get(this.formObject, 'metadata._team.headcountRange')) this.headcountRange = this.$_.clone(this.formObject.metadata._team.headcountRange)
      if (this.$_.has(this.formObject, 'metadata._team.headcountPrecision')) this.headcountPrecision = this.$_.clone(this.formObject.metadata._team.headcountPrecision)
      this.stepResult()
    })
  },
  methods: {
    stepResult () {
      this.$emit('stepResult', {
        canGoNext: (this.headcountRange && !this.headcountPrecision) || (this.headcount && this.headcountPrecision) || this.$_.get(this.step, 'optional', false),
        value: {
          metadata: {
            _team: {
              headcount: this.headcount,
              headcountRange: this.headcountRange,
              headcountPrecision: this.headcountPrecision,
            }
          }
        },
      })
    },
  },
}
</script>
