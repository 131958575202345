<template>
  <div class="row">
    <QCheckbox
      v-model="inputCheckbox"
      :disable="readonly"
      :label="computedLabel"
      style="margin-left:-10px;"
      class="col"
      @update:model-value="stepResult"
    />
    <HCButton v-if="step.isCgu" :href="$web({ path: 'cgu' })" icon="uil:external-link-alt" target="_blank" label="checkout.subscribe.cgvLink" tooltip="checkout.subscribe.terms" size="sm" class="col-1 q-ml-md" />
  </div>
</template>

<script>
export default {
  props: {
    formObject: {
      type: Object,
      default: null,
    },
    step: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['stepResult'],
  data () {
    return {
      inputCheckbox: false,
    }
  },
  computed: {
    // TODO : consider always passing trn strings, so directly use $t in :label
    computedLabel () {
      const l = this.$_.get(this.step, 'label', undefined)
      return l ? this.translationExists(l) ? this.$t({ id: l }) : l : false
    },
  },
  created () {
    this.$nextTick(() => {
      if (!this.$_.get(this.formObject, this.step.field) && this.$_.get(this.step, 'copyFromObject')) {
        this.inputCheckbox = this.$_.get(this.step, `copyFromObject.${this.step.field}`)
      } else this.inputCheckbox = this.$_.get(this.formObject, this.step.field)
      if (typeof this.inputCheckbox !== 'boolean') this.inputCheckbox = this.$_.get(this.step, 'default', false)
      this.stepResult()
    })
  },
  methods: {
    stepResult () {
      const value = {}
      this.$_.set(value, this.step.field, this.inputCheckbox)
      let canGoNext = this.inputCheckbox !== null || this.$_.get(this.step, 'optional', false)
      if (this.$_.get(this.step, 'forceAccept', false)) canGoNext = this.inputCheckbox
      this.$emit('stepResult', {
        canGoNext,
        value
      })
    }
  }
}
</script>
